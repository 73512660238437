@import 'variables.scss';

/******************************
 Quick Checkout
*******************************/

.quick-checkout-wrapper {
  > div {
    display: flex;
    flex-wrap: wrap;
    > div {
      flex-grow: 1;
      max-width: 100%;
    }
    .right {
      display: flex;
      flex-direction: column;
      .checkout-section {
        &:last-child {
          order: 1000;
          margin-bottom: 0;
        }
      }
    }
  }

  .section-body {
    display: flex;
    flex-direction: column;
    .form-group {
      order: 100;
    }
    > .radio + div {
      margin: 5px 0;
    }
    .radio, .checkbox {
      align-items: initial;
    }
  }

  .payment-address,
  .shipping-address {
    .section-body {
      > div:not(.checkbox) {
        display: flex;
        flex-direction: column;
      }
    }
  }
  #input-cc-cvv2 {
    max-width: 111px;
  }
  .payment-address {
    .checkbox {
      margin-top: 15px;
    }
  }
  .well {
    margin-bottom: 0;
    box-shadow: none;
    border: none;
    padding: 0;
    background: transparent;
  }
  .checkout-section {
    .login-options {
      margin-bottom: 0;
      order: -1;
      .radio {
        width: auto;
      }
    }
    .login-form {
      margin-top: 10px;
      > div:first-of-type {
        margin-top: 10px;
      }
    }
  }

  .shipping-payment {
    display: flex;
    flex-wrap: wrap;
    .section-payment {
      flex-grow: 1;
    }
    .section-body {
      > div {
        display: flex;
        &::before {
          line-height: 1;
          min-width: 30px;
          text-align: center;
          font-size: 22px;
        }
      }
      .alert {
        display: block;
        &::before {
          display: none;
        }
      }
    }
    .shippings {
      p {
        margin-bottom: 0;
      }
    }
  }

  .section-cvr {
    .form-group .control-label {
      max-width: 250px;
      + div {
        justify-content: flex-end;
      }
    }
  }

  .cart-section {
    table {
      margin-bottom: 0 !important;
    }
    .table-responsive + table {
      border-top-width: 0 !important;
      margin-top: 10px;
      tfoot {
        td + td {
          width: 20%;
        }
      }
    }
  }

  .confirm-section {
    textarea {
      margin-bottom: 10px;
    }
  }

  .login-active .checkout-section:not(.section-login) {
    opacity: .4;
    pointer-events: none;
  }

  // Spinner
  .quick-checkout-spinner {
    display: flex;
    justify-content: center;
  }
  // Payment details
  .payment-pagseguro,
  .payment-skrill,
  .payment-cod,
  .payment-payza,
  .payment-paypal,
  .payment-pp_standard,
  .payment-pp_express,
  .payment-coinbase,
  .payment-stripe,
  .payment-instamojo,
  .payment-twocheckout {
    display: none !important;
  }

  #payment {
    > legend {
      display: none;
    }
  }

  .quick-checkout-payment {
    position: relative;
  }

  // Payment buttons
  .quick-checkout-payment .buttons,
  .payment-pp_braintree #submit-new {
    display: none !important;
  }

  .captcha {
    margin-top: 15px;
  }

  .captcha .form-group label {
    max-width: 100%;
  }

  .captcha .form-group img {
    margin-left: 10px;
    margin-top: 1px;
  }
}

//Checkout

#checkout-checkout {
  #input-password + a {
    width: 100%;
    margin-top: 10px;
  }
  .register-page {
    display: flex;
    flex-wrap: wrap;
    > div{
      padding:10px;
      flex: 1 1 300px;
    }
  }
}
.quick-checkout-wrapper{
.confirm-section {
  .checkbox {
    display: block !important;
    margin:0;
  }
  label{
    display: block;
    input{
      top: 2px;
    }
  }
}
}

@media only screen and (max-width: 760px) {
  .confirm-section {
    .radio label, .checkbox label {
      display: block;
    }
    .radio input, .checkbox input {
      //margin: 2px 0 0 -20px;
    }
  }
}

.quick-checkout-wrapper label {
  flex-wrap: wrap;
  display: flex;
}

.section-shipping .radio label span {
  width: 100%;
}

.popup-checkout-payment {
  .popup-body {
    padding: 15px;
  }

  .popup-inner-body {
    overflow-y: auto;
    min-width: 100px;
    min-height: 50px;
    max-height: 500px;
  }

  .buttons {
    display: block;
  }
}
