@import '../variables.scss';

.module-banners {
  .module-body {
    display: flex;
    flex-wrap: wrap;
  }
  *{
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .module-item {
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      //height: 100%;
      transition: all 0.15s ease-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        transition: all 0.2s ease;
      }
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        transition: all 0.15s ease-out;
        z-index: 2;
        border-radius: inherit;
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.12s ease-out;
        font-size: 35px;
        color: white;
        z-index: 4;
      }
    }
  }
  .banner-text {
    position: absolute;
    left: 0;
    right: 0;
    color: white;
    line-height: 1.3;
    z-index: 3;
    transition: all 0.12s ease-out;
    span{
      position: relative;
      display: block;
      transition: all 0.12s ease-out;
    }
  }
  .banner-caption-3{
    span{
      display: block;
    }
  }
}
