@import '../variables.scss';

/******************************
 Mobile Header
*******************************/

.mobile-header,
.open-close,
.mobile-bar-group {
  display: none;
}

.mobile-header-active {
  .mobile-header {
    display: block;
  }
  .header {
    display: none;
  }
}

/******************************
 Header
*******************************/

header {
  z-index: 100;
  position: relative;
  .navbar-nav{
    float: none;
    margin:0;
  }
}

.header {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
}

.header,
.dropdown-menu,
.top-menu {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.top-menu{
  &.language-currency > div,
  &.language-currency > div > div,
  &.language-currency > div > div form,
  &.language-currency > div > div form > .dropdown{
    height: 100%;
  }
}

/******************************
 Logo
*******************************/

#logo {
  height: 100%;
  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.075s ease-out;
    > img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      position: relative;
      align-self: center;
    }
  }
  h1 {
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

/******************************
 Language & Currency
*******************************/

.language-currency {
  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: none;

  > div:first-of-type:not(:only-of-type) {
    margin-right: 7px;
  }

  .dropdown{
    pointer-events: auto;
    &::after {
      content: "\f0da";
      font-family: $icons !important;
      font-size: 11px !important;
      position: relative;
      padding: 0 5px;
      margin-left: auto;
      transform: rotate(90deg);
      margin-top: 2px;
      font-weight: normal !important;
    }
  }
  .dropdown-toggle {
    padding: 0;
  }

  .symbol {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      align-self: center;
    }
  }

  button {
    background-color: transparent;
    margin: 0;
    width: 100%;
    white-space: nowrap;
    padding: 7px 10px;
  }

  a {
    white-space: nowrap;
    cursor: pointer !important;
  }

  .dropdown > button > span {
    display: flex;
    align-items: center;
  }

  .dropdown-menu {
    min-width: auto;
    > ul {
      > li {
        border-width: 1px 0 0 0;
        &:first-child {
          border-top-width: 0;
        }
        &::before {
          display: none;
        }
      }
    }
  }
}

#language,
#currency {
  display: flex;
  align-items: center;
  form > .dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    flex-direction: row;
  }
}

.currency-symbol-title > .currency-symbol {
  width: 16px;
  height: 16px;
  background-color: #EA2349;
  color: white;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.language-flag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 18px;
  img {
    max-width: none;
    align-self: center;
  }
}

.currency-code,
.currency-code-dropdown {
  display: none;
}

.language-title,
.currency-title,
.currency-code,
.currency-title-dropdown,
.language-title-dropdown,
.currency-code-dropdown {
  margin-left: 5px;
}

.currency-select,
.language-select {
  display: flex;
  align-items: center;
}


/******************************
 DESKTOP HEADER STYLES
*******************************/

.desktop-header-active {


  header {
    &::before {
      width: 100%;
      height: 35px;
      position: absolute;
      top: 0;
      content: "";
      z-index: 0;
    }
    .top-bar {
      z-index: 1001;
      position: relative;
    }
  }

  /******************************
   Default
  *******************************/

  .header-default {

    > div {
      height: 100%;
    }

    .header-assets {
      flex-grow: 1;
      width: calc(100% - 250px);
    }

    .top-bar {
      height: 33.3333%;
      display: flex;
      align-items: center;
      .dropdown > a::after {
        margin-left: 0;
      }
    }

    .mid-bar {
      height: 33.3333%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .top-menu > ul > li + li {
        border-width: 0;
      }
    }

    .top-menu,
    .top-menu > ul,
    #cart,
    .desktop-cart-wrapper {
      height: 100%;
    }

    .full-cart {
      margin-left: auto;
    }

    .header-cart-group {
      display: flex;
      align-items: center;
    }
    .desktop-logo-wrapper {
      width: 250px;
    }
    .desktop-main-menu-wrapper {
      position: static;
      .main-menu > .j-menu, .main-menu-item {
        flex-grow: 1;
      }
    }
  }

  .header-default {
    .header-assets {
      flex-grow: 1;
      > div {
        height: 33.3333%;
      }
    }
    .cart-heading {
      justify-content: flex-end;
    }
    .desktop-main-menu-wrapper {
      .main-menu {
        display: flex;
      }
    }
    .top-bar {
      > .top-menu {
        flex-grow: 1;
        > ul {
          padding: 0;
          > li {
            flex: 1 0 0;
            > a {
              justify-content: center;
            }
          }
        }
      }
    }

    .full-search, .full-search #search {
      height: 100%;
    }
    .top-menu-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      height: 100%;
    }
    &::before {
      z-index: -1;
    }
  }

  body:not(.is-sticky) {
    .header-default {
      .main-menu {
        > .menu-item {
          flex-grow: 1;
          height: 100%;
          > a {
            justify-content: center;
          }
        }
      }
    }
  }

  /******************************
   Classic
  *******************************/

  .header-classic {
    flex-direction: column;
    > div {
      width: 100%;
    }
    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .mid-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100px;
      > div {
        height: 100%;
        order: 5;
        display: flex;
        align-items: center;
      }
      .top-menu{
        height: auto;
        margin:0 10px;
        > .j-menu{
          flex-wrap: nowrap;
        }
      }
      .secondary-menu {
        margin: 0;
        .top-menu{
          margin: 0;
        }
      }
    }
    #logo {
      width: 100%;
      a {
        width: 100%;
      }
    }
    .classic-cart-wrapper,
    .desktop-cart-wrapper {
      justify-content: flex-end;
    }
    .desktop-search-wrapper{
      min-width: 160px;
    }
    .desktop-main-menu-wrapper{
      display: flex;
    }
    .header-cart-group {
      justify-content: flex-end;
    }
    .full-search #search {
      height: 35px;
      width: 100%;
    }
    #cart{
      flex:1;
      > a{
        justify-content: flex-end;
      }
    }
  }

  /******************************
   Mega
  *******************************/

  .header-mega {
    flex-direction: column;
    > div {
      width: 100%;
    }
    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 35px;
    }
    .mid-bar {
      display: flex;
      align-items: center;
      height: 100px;
      > div {
        height: 100%;
      }
    }
    .mid-bar {
      > div {
        display: flex;
        align-items: center;
      }
      .desktop-cart-wrapper{
        height: auto;
      }
      .secondary-menu{
        flex: 1;
        .top-menu{
          height: auto;
        }
      }
    }
    #logo {
      margin: 0 auto;
      width: 100%;
      a {
        width: 100%;
        height: 100%;
      }
    }
    .desktop-search-wrapper {
      flex-grow: 1;
      justify-content: flex-end;
      display: flex;
      height: 100%;
      order:2;
      > div {
        width: 100%;
      }
    }
    .desktop-logo-wrapper{
      margin-right: auto;
    }
    .desktop-cart-wrapper {
      justify-content: flex-end;
      height: 100%;
      order: 5;
      #cart, .cart-heading {
        height: 100%;
      }
    }
    .desktop-main-menu-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //position: relative; Static for sticky transition
      #main-menu-2{
        order: 4;
      }
    }
    .language-currency {
      pointer-events: none;
      button {
        pointer-events: auto;
      }
    }
    #main-menu{
      order: -1;
    }
  }

  /******************************
   Compact
  *******************************/

  .header-compact {
    transition: background-color .15s ease;
    .language-currency {
      pointer-events: none;
      button {
        pointer-events: auto;
      }
    }
    flex-direction: column;
    > div {
      width: 100%;
    }
    .top-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .mid-bar {
      display: flex;
      align-items: center;
      transition:height .2s ease;
      z-index: 1;
      .desktop-main-menu-wrapper {
        position: static;
        &::before {
          display: none;
        }
        .main-menu {
          display: inline-flex;
        }
        .mega-fullwidth > .j-dropdown {
          padding-top: 14px;
          margin-top: -14px;
        }
      }
    }
    .desktop-logo-wrapper {
      height: 100%;
    }
    .desktop-main-menu-wrapper {
      flex: 1;
      display: flex;
      height: auto;
      .main-menu {
        flex-wrap: nowrap;
        > .j-menu > .menu-item {
          > a {
            position: relative;
            z-index: 2;
          }
          > .dropdown-menu {
            z-index: 1;
          }
        }
      }
    }
    .header-cart-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: auto;
    }
    .top-menu-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      padding: 0 10px;
    }
  }
  /******************************
   Slim
  *******************************/

  .header-slim {
    .desktop-main-menu-wrapper {
      height: 100%;
      flex: 0;
    }
    .header-cart-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .full-search {
      display: flex;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      #search {
        width: 100%;
        height: 40px;
      }
    }
  }
  .header-sm{
    .top-menu > ul {
      margin: 0;
      flex-wrap: nowrap;
    }
  }
  .mid-bar{
    position: relative;
  }
}

.search-page-open.header-compact .top-bar{
  z-index: 1;
}

/******************************
 Sticky Header
*******************************/

.desktop-header-active {
  .is-sticky {
    .desktop-main-menu-wrapper {
      transition: top 0.25s ease-out;
      position: fixed;
      top: 0;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      &::before {
        width: 100vw;
        left: 50%;
        margin-left: -50vw;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
      }
      .count-badge{
        top: 0;
        margin-top: 0 !important;
      }
    }
    .header-classic{
      .desktop-main-menu-wrapper{
        .desktop-cart-wrapper,#cart,.cart-heading{
          height: 100%;
        }
      }
    }
    .header-default {
      .desktop-main-menu-wrapper::before {
        background: inherit;
      }
    }
  }
  &.compact-sticky .sticky-compact header{
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
}
