@import '../variables.scss';

.module-newsletter{
  display:flex;
  flex-direction:column;
  .module-body{
    display: flex;
    width: 100%;
    justify-content: inherit;
    flex-wrap: wrap;
     > div{
       display: flex;
       justify-content: inherit;
     }
    .newsletter-form{
      max-width: 100%;
      form{
        max-width: 100%;
        input{
          max-width: 100%;
        }
      }
      .checkbox{
        padding:0
      }
    }
  }
  .btn {
    position: absolute;
    right: 0;
    z-index: 2;
    &::before{
      content: "\f0e0";
      font-family: $icons;
    }
  }
  .input-group + .checkbox label{
    display: block;
    margin-top: 5px;
    width: 100%;
  }
}

.agree b{
  font-weight: inherit;
}

.side-column{
  .module-newsletter{
    .module-body{
      display: block;
    }
    .btn {
      span {
        display: none;
      }
    }
  }
  .newsletter-text{
    margin-bottom:10px;
  }
}
