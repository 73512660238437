@import 'variables.scss';

/******************************
 Globals
*******************************/

html[dir="rtl"] {
  .text-left {
    text-align: right;
  }
  .text-right {
    text-align: left;
  }
  .form-control {
    text-align: right;
  }
  .fa-upload{
    margin-right: auto;
    margin-left: 5px;
  }
  .buttons .pull-right ::after{
    margin-right: 3px;
  }
}

/******************************
 Header Cart
*******************************/

html[dir="rtl"] {
  .cart-content {
    right: auto;
    left: 0;
    &::before {
      left: -100%;
      right: auto;
      transform: translateX(150%);
    }
    .table-striped tbody .td-image {
      padding-right: 10px;
    }
  }
  .full-cart {
    .cart-heading {
      padding-right: 0;
      padding-left: 40px;
      i {
        right: auto;
        left: 0;
      }
    }
  }
  .mini-cart {
    .count-badge {
      right: auto;
      left: 0;
    }
  }
  #cart-items {
    right: auto;
    left: 0;
  }
}

/******************************
 Tabs
*******************************/

html[dir='rtl']{
  .nav-tabs{
    > li{
      > a{
        &::before{
          margin-right: auto;
          margin-left: 5px;
        }
      }
    }
  }
}

/******************************
 Countdown
*******************************/
html[dir='rtl']{
  .countdown {
    > div {
      &:first-of-type {
        border-left-width: 1px;
      }
      &:last-of-type {
        border-left-width: 0;
      }
    }
  }
}

/******************************
Stepper
*******************************/

html[dir='rtl']{
  .stepper .form-control {
    text-align: center;
  }
}

/******************************
 Search
*******************************/

html[dir="rtl"] {
  .mini-search {
    #search > .dropdown-menu {
      left: 0;
      right: auto;
      &::before {
        left: -100%;
        transform: translateX(100%);
      }
    }
  }
  .search-button{
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .search-categories {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    .j-dropdown {
      &::before {
        left: auto;
        right: 10px;
      }
    }
  }
  .search-categories-button {
    &::after {
      margin-right: 5px;
      margin-left: 0;
    }
  }
  .tt-menu {
    > div {
      margin-left: 0;
      margin-right: auto;
    }
  }
  .tt-menu:not(.tt-empty) {
    &::before {
      right: auto;
      left: 10px;
    }
  }
}


/******************************
 Main Menu
*******************************/

html[dir="rtl"] {
  .desktop-main-menu-wrapper {
    .main-menu {
      > div:first-of-type {
        border-right-width: 0;
        border-left-width: 1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
      > div:last-of-type {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        border-left-width: 0;
      }
    }
  }
  .main-menu {
    .menu-label{
      right: auto;
      left: 0;
    }
  }
  .count-badge {
    margin-left: 0;
    margin-right: 5px;
  }
}

/******************************
 Multi-Level
*******************************/

html[dir="rtl"] {
  .multi-level {
    .dropdown-menu {
      ul li {
        .dropdown-menu {
          left: auto;
          right: 100%;
        }
        &.dropdown {
          > a::after {
            margin-right: auto;
            margin-left: 0;
            transform:scale(-1);
          }
        }
      }
    }
  }
}

/******************************
 Mobile Header
*******************************/

html[dir='rtl']{
  .mobile-header:not(.mobile-2){
    .top-menu > ul > li:first-of-type{
      > .dropdown-menu{
        left: auto;
        right: -5px;
        &::before{
          left: auto;
          right: 5px;
        }
      }
    }
   .language-currency.top-menu > div:last-child .dropdown.drop-menu.animating > .j-dropdown{
     left: -5px;
     right: auto;
     &::before{
       left: auto !important;
       right: calc(100% - 5px) !important;
       transform: translateX(100%) !important;
     }
   }
  }
}
/******************************
 Mobile Wrapper
*******************************/

html[dir='rtl'] {
  .mobile-container {
    transform: translate3d(100%, 0, 0);
    right: 0;
    &.mobile-cart-content-container{
      transform: translate3d(-100%, 0, 0);
      left: 0;
      right: auto;
    }
  }
}

/******************************
 Mobile Main Menu
*******************************/

html[dir='rtl']{
  .mobile-main-menu-wrapper {
    .main-menu {
      .menu-item {
        .open-menu {
          right: auto;
          left: 8px;
        }
      }
    }
  }
}

/******************************
 Headers
*******************************/

html[dir='rtl'].desktop-header-active {
  .header-mega {
    .header-cart-group {
      margin-right: auto;
      margin-left: 0;
    }
    .info-block {
      .count-badge {
        right: auto;
        left: 0;
      }
    }
  }
  .header-compact {
    .language-currency + .top-menu {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  //Sticky
  .is-sticky {
    //Boxed
    &.boxed-sticky {
      .desktop-main-menu-wrapper {
        &::before {
          left: 0;
          margin-left: 0;
        }
      }
    }
  }
  &.mobile-menu-active{
    .is-sticky{
      .menu-trigger{
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
}



/******************************
 Flyout
*******************************/

html[dir="rtl"] {
  .flyout-menu {
    .menu-item {
      .j-dropdown {
        left: auto;
        right: 100%;
      }
      &.dropdown > a::after {
        margin-right: auto;
        margin-left: 0;
        transform:scale(-1);
      }
    }
    .count-badge + .open-menu + .menu-label{
      margin-left: 7px;
      margin-right: 0;
    }
  }
}

/******************************
 Accordion Menu
*******************************/

html[dir="rtl"] {
  .accordion-menu {
    .open-menu {
      right: auto;
      left: 8px;
    }
    .menu-item {
      > a {
        &::before {
          margin-right: 0;
          margin-left: 5px;
        }
      }
      > div .menu-item a::before {
        margin-right: 0;
      }
    }
  }
}

/******************************
 Newsletter
*******************************/

html[dir='rtl'] {
  .module-newsletter {
    .module-body {
      .input-group-btn {
        right: auto;
        left: 0;
        button{
          left: 0;
          right:auto;
        }
      }
    }
  }
}

/******************************
 Language & Currency
*******************************/

html[dir="rtl"] {
  .language-title,
  .currency-title,
  .currency-code,
  .currency-title-dropdown,
  .language-title-dropdown,
  .currency-code-dropdown{
    margin-left: auto;
    margin-right: 5px;
  }
}

/******************************
 Links Menu
*******************************/

html[dir='rtl'] {
  .links-menu {
    .menu-item {
      &:first-child {
        border-right-width: 0;
      }
      a .menu-label{
        transform: translateX(-100%);
      }
    }
  }
}

/******************************
 Products Filter
*******************************/

html[dir="rtl"] {
  .products-filter {
    .select-group {
      margin-left: 0;
      margin-right: auto;
    }
  }
  &.mac.webkit {
    .select-group {
      .form-control {
        left: auto;
        right: -3px;
      }
    }
  }
  .grid-list .compare-btn::before {
    margin-right: 0;
    margin-left: 5px;
  }
}

/******************************
 Mobile Bottom Menu
*******************************/

html[dir='rtl'] .bottom-menu > ul > li + li {
  border-width: 0 1px 0 0;
}

/******************************
 Pagination
*******************************/

html[dir='rtl'] {
  .pagination-results {
    > div:first-child:not(:empty) + div {
      margin-left: 0;
      margin-right: auto;
      white-space: nowrap;
    }
  }

  .pagination {
    > li {
      &:not(:first-of-type) {
        border-width: 0 1px 0 0;
      }
      &:first-child,
      &:last-child,
      &.prev, &.next {
        a {
          &::before {
            transform: translate(-50%, -50%) scaleX(-1);
          }
        }
      }
      &:last-child,
      &.next {
        a::before {
          transform: translate(-50%, -50%) scaleX(1);
        }
      }
      &:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        a, span{
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      &:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        a, span{
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
  .header-search input {
    text-align: right;
  }
  &.desktop .module-title button {
    right: auto;
    left: 0;
  }
  table td:last-child, table th:last-child, .table-bordered td:last-child, .table-bordered th:last-child {
    border-left-width: 0;
  }
  .post-stats span{
    margin-right: 0;
    margin-left: 8px;
  }
  .side-product > div {
    float: right;
  }
  .lg-outer {
    direction: ltr;
    #lg-counter {
      float: right;
      padding-right: 20px;
      padding-left: 0;
    }
    #lg-counter-all{
      float: left;
      display: inline-block;
      padding:0 4px
    }
    #lg-counter-current{
      float: right;
      display: inline-block;
      padding:0 4px
    }
    .lg-thumb-item{
      float: right;
    }
  }

  .lg-actions{
    .lg-next{
      left: 20px;
      right: auto;
    }
    .lg-prev {
      right: 20px;
      left: auto;
    }
  }
  .lg-toolbar .lg-icon{
    float: left;
  }
  .drop-cap {
    float: right;
  }
  blockquote::before {
    float: right;
  }
  &.route-product-search #content .search-form select{
    margin-left: 0;
    margin-right: 20px;
  }
  .radio, .checkbox {
    padding: 0 0 0 15px;
  }
  &.route-product-manufacturer .manufacturer > div {
    padding: 0 0 15px 15px;
  }
  .dropdown > a::after {
    margin-left: auto;
    margin-right: 5px;
  }
  .user-avatar {
    float: right;
  }
  .reply-btn {
    left: 10px;
    right: auto;
  }
  .user-data div span {
    margin-right: 0;
    margin-left: 5px;
  }
  .post-comment .user-name {
    padding-right: 0;
    padding-left: 50px;
  }
  .product-list .product-thumb .image{
    float: right;
  }
  footer .links-menu .module-title::before{
    right: auto;
    left: 0;
  }
  .popup-close{
    left: 0;
    right: auto;
  }
}

