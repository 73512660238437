//Admin Bar
.admin-bar-wrapper {
  background: lightskyblue;
  z-index: 1000;
  position: relative;

  .admin-bar {
    margin: 0 auto;
    max-width: 980px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 35px;
  }
}

.phone .swiper-container {
  overflow: hidden !important;
}

.mobile-test {

  // disable pagination
  .swiper-pagination-bullet {
    display: none !important;
  }

  // swiper next prev buttons
  .swiper-buttons div {
    min-width: 48px;
    min-height: 48px;
  }

  // change font global font size
  body {
    font-size: 16px;
  }

  // change module catalog links font size
  .module-catalog .subitem a {
    font-size: 16px;
  }

  // module catalog padding
  .module-catalog .subitem a {
    padding: 8px;
  }

  .module-catalog .subitem.view-more a span {
    font-size: 16px;
  }

  // increase mobile top bar height
  .mobile-top-bar {
    min-height: 48px !important;
  }

  // mobile custom menu icons
  .mobile-custom-menu {
    min-width: 34px !important;
  }

  // padding for stats (increased from 6px)
  .module-products .stats {
    padding: 8px !important;
  }

  // padding for buttons
  .module-products .btn {
    // general
    //padding: 12px !important;

    // demo 8
    padding: 20px !important;
  }

  // side products btn padding
  .module-side_products .btn {
    padding: 8px !important;
  }

  // padding for links menu
  .links-menu a {
    padding: 8px;
  }

  // popup close button
  .popup-close {
    margin: 20px;

    &::before {
      //padding: 10px;
    }
  }

  // demo 2 newsletter
  .module-newsletter .input-group + .checkbox label {
    margin-top: 14px;
  }

  // demo 5 acc menu
  .accordion-menu.accordion-menu .j-menu > li > a {
    padding: 8px;
  }

  // demo 6
  &.mobile-header-active #cart > a > i {
    min-height: 40px;
  }

  // demo 8
  .module-products-300 .product-grid .product-thumb .name {
    margin-top: 12px;
  }

  // demo 11
  .module-notification .notification-close {
    min-width: 48px;
    min-height: 48px;
  }
}
