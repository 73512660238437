@import 'variables.scss';

/******************************
 General
*******************************/

::before,
::after {
  position: relative;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

.fa {
  position: relative;
}

.h1, .h2, .h3, h1, h2, h3, .h4, .h5, .h6, h4, h5, h6 {
  margin: 0;
  font-weight: 700;
}

p {
  margin: 0;
}

s {
  text-decoration: none;
  font-size: 70%;
  font-style: normal;
  font-weight: normal;
  white-space: normal;
  line-height: 1;
  max-width: 125px;
  transition: all 0.075s ease-out;
}

/******************************
 Images
*******************************/

img {
  max-width: 100%;
  height: auto;
  align-self: flex-start; //Stretches images height
}

.img-thumbnail {
  padding: 0;
  border-radius: 0;
  border: none;
  background: transparent;
}

.language-flag {
  max-width: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

@keyframes fa-rotate {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/******************************
 Buttons
*******************************/

a, button {
  touch-action: manipulation;
}

a:focus, a:hover {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

button,
.btn,
.btn-default {
  &:focus,
  &.active:focus,
  &:active:focus {
    outline: none;
  }
}

button,
.btn,
.btn-default {
  &.active,
  &:active {
    box-shadow: none;
  }
}

.btn.disabled {
  pointer-events: none;
  position: relative;
  opacity: 1;

  &::before,
  > span {
    opacity: 0;
  }

  &::after {
    content: "";
    position: absolute;
  }
}

.btn.btn-full {
  width: 100%;
}

.btn {
  border: none;
  border-radius: 0;
  background: transparent;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  line-height: 1;
  min-width: 0;
  transition: all 0.1s ease-out;

  > span:first-of-type {
    //padding: 0 .4em;
    min-width: 0;
    white-space: nowrap;
  }

  > .links-text:empty {
    display: none;
  }

  .count-badge {
    margin: 0;
  }

  &:hover, &:focus, &.focus {
    //color: inherit;
  }
}

.fa-upload {
  margin-right: 5px;
}

:focus {
  outline: none;
}

/******************************
 Page Buttons
*******************************/

.buttons {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  transition: all 0.1s ease-out;

  .pull-left {
    margin-right: auto;
  }

  .pull-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    > a:not(.btn) {
      margin: 0 5px;

      + input {
        margin: 0 5px 0 0;
      }
    }
  }

  input[type=checkbox] {
    margin: 0 4px;
  }
}

fieldset,
.pagination-results {
  + .buttons {
    margin-top: 20px;
  }
}

/******************************
 Count Badge
*******************************/

.count-badge {
  font-size: 10px;
  margin-left: 5px;
  min-width: 1.65em;
  height: 1.65em;
  padding: 0 3px;
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  line-height: 0;
}

/******************************
 Rating
*******************************/

.fa-stack {
  font-size: 15px;
  width: 1.1em;
  height: 1em;
  line-height: 1;

  i {
    font-size: 1em;
  }
}

.fa-star,
.fa-star + .fa-star-o {
  color: gold;
}

.fa-stack-1x, .fa-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}

/******************************
 Product Labels
*******************************/

.product-labels {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}

.product-label {
  position: relative;
  pointer-events: none;
  order: 5;

  b {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: opacity .2s;
    font-weight: normal;
    font-size: 12px;
    white-space: nowrap;
    line-height: 1;
  }
}

.product-label-diagonal {
  top: 0;
  left: 0;
  width: 300px;
  transform: translate3d(-130px, 8px, 0) rotate(-45deg);
  font-size: 15px;
  text-align: center;
  position: absolute;
  margin: 0;
  padding: 0;

  b {
    display: block;
    background: transparent;
  }

  &.label-2 {
    transform: translate3d(-112px, 26px, 0) rotate(-45deg);
  }

  &.label-3 {
    transform: translate3d(-94px, 43px, 0) rotate(-45deg);
  }
}

html[dir='rtl'] {
  .product-label-default {
    text-align: left;
  }

  .product-label-diagonal {
    left: auto;
    right: 0;
    text-align: center;
    transform: translate3d(calc(0px - (-130px)), 8px, 0) rotate(45deg);

    &.label-2 {
      transform: translate3d(calc(0px - (-112px)), 26px, 0) rotate(45deg);
    }

    &.label-3 {
      transform: translate3d(calc(0px - (-94px)), 43px, 0) rotate(45deg);
    }
  }
}

/******************************
 Countdown
*******************************/

.countdown {
  position: relative;
  color: white;
  background-color: rgba(0, 0, 0, .4);
  transition: all 0.2s;
  visibility: visible;
  opacity: 1;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  //pointer-events: none;
  font-size: 12px;

  > div {
    width: 25%;
    padding: 2px;
    border-width: 0 0 0 1px;

    &:first-of-type {
      border-left-width: 0;
    }

    span {
      display: block;
      font-size: 10px;
      font-weight: normal;
      position: relative;
      top: -3px;
      padding: 0 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .product-thumb:hover & {
    opacity: 1;
  }
}

.touchevents {
  .countdown {
    opacity: 1;
  }
}

.module-countdown .countdown {
  margin: 0 auto;
}

/******************************
 Forms
*******************************/

label {
  font-weight: normal;
  position: relative;
}

legend {
  border: none;
  margin: 10px 0;
  color: inherit;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0;
  transition: all 0.05s ease-out;

  &:empty {
    display: none !important;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
  }

  .text-danger,
  .text-success {
    width: 100%;
    margin-top: 5px;
  }

  .control-label {
    padding: 0;
    margin: 0;
    width: auto;
    text-align: left;
    float: none;
    display: flex;
    border-width: 0 0 1px 0;
    align-self: flex-start;
    width: 100%;

    + div {
      width: auto;
      flex: 1 1 200px;
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
      float: none;
    }
  }

  .input-group {
    flex-wrap: nowrap;
  }

  fieldset {
    width: 100%;
  }
}

.radio,
.checkbox {
  padding: 0 15px 0 0;
  margin: 0;
  display: inline-flex;
  align-items: center;

  input {
    margin: 0;
  }

  label {
    display: inline-block;
    align-items: center;

    input[type='checkbox'] {
      margin-top: 3px;
    }
  }

  a {
    padding: 0 3px;
  }
}

.checkbox-inline,
.radio-inline {
  display: flex;
  align-items: center;

  input {
    margin: 0;
  }
}

.radio + .radio,
.radio + .checkbox {
  margin-top: 0;
}

.label-info {
  display: inline-block;
}

.form-group > .col-sm-2 {
  > input[type=checkbox] {
    margin-top: 10px;
  }
}

.form-control {
  border-radius: 0 !important;
  border-color: transparent;
  line-height: 1.3;
  color: #555;
  background-color: white;
  transition: all 0.07s ease-out;
  box-sizing: border-box;
  box-shadow: none;
}

input.form-control {
  min-width: 35px;
  transition: all 0.075s ease-out;
  width: auto;
  flex-grow: 1;
}

.has-error {
  .form-control {
    box-shadow: none;
  }

  .control-label,
  .text-danger {
    color: inherit;
  }
}

.input-group {
  display: flex;
  align-items: center;

  input.form-control {
    height: 100%;
    display: flex;
  }

  .input-group-btn {
    width: auto;
    height: 100%;
  }

  .btn {
    height: 100%;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

#input-captcha {
  max-width: 140px;

  + img {
    margin: 0 7px;
  }
}

.form-horizontal {
  .form-group {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;

    &:last-child {
      //margin-bottom: 0;
    }
  }

  .captcha {
    .form-group {
      margin: 0;
    }

    legend {
      display: none;
    }
  }

  .g-recaptcha {
    transform: scale(.92);
    transform-origin: 0 0;
  }

  .radio, .checkbox {
    padding-top: 5px;
    min-height: 20px;
  }

  .radio-inline,
  .checkbox-inline {
    padding-top: 0;
  }
}

fieldset + fieldset {
  margin-top: 20px;
}

.required {
  .control-label {
    &::after {
      content: "*";
      position: relative;
      font-family: inherit;
      margin-left: 3px;
      line-height: 1.2;
      max-height: 10px;
    }
  }
}

.required {
  .control-label + div {
    &::before {
      display: none;
      content: "*";
      position: absolute;
      transform: translateX(-150%);
      font-family: inherit;
      line-height: 1.2;
      max-height: 10px;
    }
  }
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  margin: 0;
}

/******************************
 Date Time
*******************************/

.bootstrap-datetimepicker-widget {
  width: auto;
  tr td {
    position: relative;

    span {
      position: relative;

      &:hover {
        background: transparent;
        z-index: 2;
      }

      &.active {
        background-color: inherit;
        color: inherit;
        text-shadow: inherit;
        z-index: 3;
      }
    }

    &:hover, &.active {
      z-index: 2;
    }

    &.separator {
      color: inherit;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        color: inherit !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }

  .datepicker-months,
  .datepicker-years,
  .timepicker-picker {
    tr td {
      color: inherit !important;
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .picker-switch + .collapse {
    .timepicker-hours,
    .timepicker-minutes {
      margin-top: 10px;
    }
  }

  &::before {
    display: none !important;
  }

  .timepicker-picker .btn {
    margin: 10px 0;
  }

  .picker-switch .btn {
    margin-top: 10px;
  }
}

/******************************
 Stepper
*******************************/

.stepper {
  display: inline-flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 100%;
  border-width: 1px;
  min-height: 30px;
  font-size: 15px;
  transition: all 0.05s ease-out;

  .form-control {
    width: 100%;
    height: 100%;
    min-height: 28px;
    border-radius: inherit !important;
    border-width: 0;
    text-align: center;
    padding: 0 20px 0 2px;
    font-size: 13px;
    transition: all 0.05s ease-out;
  }

  span {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-width: 0 0 0 1px;
    border-color: inherit;
    border-style: inherit;
    z-index: 10;

    i {
      cursor: pointer;
      width: 100%;
      min-width: 18px;
      background: #E6E6E6;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50%;
      transition: all 0.05s ease-out;

      &:hover {
        background: lightgrey;
      }

      &:active {
        box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, .3);
      }

      &:first-of-type {
        border-width: 0 0 1px 0;
        border-color: inherit;
        border-style: inherit;

        &::before {
          font-family: icomoon;
          content: "\e93e";
          transform: rotate(90deg);
        }
      }

      &:last-of-type {
        &::before {
          font-family: icomoon;
          content: "\e93f";
          transform: rotate(90deg);
        }
      }
    }
  }
}

a, button, .btn {
  :active {
    opacity: .9;
  }
}

i.fa {
  font-style: normal;
}

/******************************
 Table
*******************************/

table {
  border-collapse: inherit;

  td {
    padding: 10px 15px;
  }
}

.table-bordered > tbody > tr > td {
  border-top-width: 0;
  border-right-width: 0;
}

.table-responsive {
  margin-bottom: 0;
  border-width: 0;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
}

.table-responsive {
  border: 0;

  > table {
    border: none !important;
  }
}

.table, table, .table-responsive > .table {
  margin-bottom: 0;
  border-width: 0;
}

.table-hover > tbody > tr:hover {
  background-color: transparent;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: transparent;
}

html[dir='rtl'] {
  .table {
    max-width: initial;
  }
}

//Border Color NOT BORDERED

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px 14px;
  border-top-color: transparent;
  vertical-align: middle;
}

//Border Color BORDERED

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border-color: transparent;
}

.table-bordered {
  border-color: transparent;
}

.table {
  border-color: transparent;

  > tbody + tbody {
    border-top: 0;
  }
}

table, .table-bordered {
  td:first-child, th:first-child {
    border-left-width: 0;
  }

  td:last-child, th:last-child {
    border-right-width: 0;
  }

  thead > tr > td {
    border-bottom-width: 1px;
    border-right-width: 0;
  }

  tbody tr:last-child {
    td, th {
      border-bottom-width: 0;
    }
  }

  tfoot > tr > td {
    border-bottom-width: 0;
    border-right-width: 0;
  }
}

table > thead > tr > td {
  border-bottom-width: 1px;
}

table > thead:first-of-type > tr > td {
  border-top-width: 0 !important;
}

.table > tbody:first-child > tr:first-of-type > td {
  border-top-width: 0 !important;
}

@media screen and (max-width: 767px) {
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > thead > tr > th {
    white-space: normal;
  }
}

.product-compare {
  #content {
    tbody td {
      text-align: center;
    }
  }
}

.route-account-wishlist,
.route-checkout-cart,
.route-checkout-checkout {
  #content table td {
    min-width: 100px;
  }
}

.bootstrap-datetimepicker-widget td,
.bootstrap-datetimepicker-widget th {
  border-width: 0;
}

/******************************
 Tooltips
*******************************/

.tooltip {
  z-index: 10001;
  pointer-events: none;
  opacity: 1;

  &.in {
    opacity: 1;
    pointer-events: none;
  }
}

.tooltip-inner {
  color: white;
  background: $dark;
  border-radius: 5px;
  font-size: 13px;
  padding: .2em .6em;
}

.tooltip.top .tooltip-arrow {
  border-top-color: $dark;
}

.tooltip.right .tooltip-arrow {
  border-right-color: $dark;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: $dark;
}

.tooltip.left .tooltip-arrow {
  border-left-color: $dark;
}

//Animate
.tooltip.fade {
  > * {
    opacity: 0;
    visibility: hidden;
    transition: all 0.15s ease-in-out;
  }

  &.in > * {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

//Top
.tooltip {
  &.top > * {
    transform: translate3d(0, 7px, 0);
  }

  &.right > * {
    transform: translate3d(-7px, 0, 0);
  }

  &.bottom > * {
    transform: translate3d(0, -7px, 0);
  }

  &.left > * {
    transform: translate3d(7px, 0, 0);
  }
}

/******************************
 Alerts
*******************************/

.alert {
  margin-bottom: 0;
  border: 0;
  padding: 10px 25px 10px 15px;
  border-radius: 0;
  position: relative;

  i {
    margin: 5px;
  }

  .close {
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    right: 10px;
    opacity: 1;
  }
}

/******************************
 Tags
*******************************/

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    transition: all 0.075s ease-out;
  }
}

/******************************
 RESPONSIVE VIDEO
*******************************/

.video-responsive {
  break-inside: avoid;
  width: 100%;
  position: relative;
  //margin:0 auto;
}

.video-frame {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

iframe {
  max-width: 100%;
  border: none;
}

/******************************
 Facebook
*******************************/

.fb-page {
  overflow-x: auto;
  max-width: 100%;
  display: block !important;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: visible;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 0;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/******************************
 Drop Cap
*******************************/

.drop-cap {
  position: relative;
  display: block;
  float: left;
  line-height: 1;
}

.amp {
  display: inline-block;
  position: relative;
  line-height: 1;
}

/******************************
 Blockquote
*******************************/

blockquote {
  border: none;
  padding: 0;
  margin: 0;
  border: none;
  font-size: inherit;

  &::before {
    float: left;
    line-height: 1;
    font-size: 2.5em;
    font-style: normal;
  }

  &::after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
  }
}

/******************************
 No transitions
*******************************/

.no-transitions * {
  transition: all 0s !important;
}

/******************************
 Share Reactions
*******************************/

.sharethis-inline-reaction-buttons {
  display: inline-block !important;

  .st-btn {
    font-size: 18px !important;
    padding: 0 7px !important;
    width: auto !important;

    &.st-selected {
      transform: scale(1) !important;
    }

    > svg {
      width: 30px !important;
      height: 30px !important;
    }
  }
}

/******************************
Link Resets
*******************************/

.cart-heading,
.info-block,
.nav-tabs li a,
.btn,
.name a,
.catalog-title,
.subitem a,
.panel-title a,
.refine-item a,
.compare-btn,
.search-result a,
.j-menu > li > a,
.dropdown-toggle > a,
.menu-item > a,
.account-list a,
.tags a,
.module-blog_categories a,
.module-blog_comments a,
.blog-feed,
.mobile-custom-menu,
.slider-static-text,
.x {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
