@import 'variables.scss';

body {
  overflow-x: hidden;
}

/******************************
 Main Structure
*******************************/

.wrapper, .mega-menu-content, .site-wrapper > .container {
  max-width: 1024px;
}

.wrapper {
  margin: 0 auto;
  position: relative;
}

.site-wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  > .container {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    > .row {
      display: flex;
      flex-grow: 1;
    }
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
  > div {
    padding-left: 0;
    padding-right: 0;
  }
}

.site-wrapper > .container,
.site-wrapper > .container > .row {
  &::before, &::after {
    display: none;
  }
}

#content {
  width: 100%;
  .one-column & {
    max-width: calc(100% - 200px);
  }
  .two-column & {
    max-width: calc(100% - 200px * 2);
  }
}

.side-column {
  width: 100%;
  max-width: 200px;
}

.phone {
  .side-column {
    display: none !important;
  }
  #content {
    max-width: 100% !important;
  }
}

.left-column-disabled,
.right-column-disabled {
  #column-left,
  #column-right {
    display: none
  }
  &.one-column,
  &.two-column {
    #content {
      max-width: 100%;
    }
  }
}

/******************************
 Page Titles
*******************************/

.title {
  position: relative;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  span::after{
    top: 1px;
  }
}

html:not(.popup) .site-wrapper > h1.page-title {
  > span {
    display: block;
    margin: 0 auto;
  }
  &::after {
    display: none !important;
  }
}

/******************************
 Title Module
*******************************/

.title-module {
  h3 {
    display: inline-block;
    margin: 0;
  }
  .title-divider {
    margin: 0 auto;
    &::before{
      line-height: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .subtitle {
    display: inline-block;
  }
  .title-wrapper {
    position: relative;
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.title-wrapper {
  margin: 0 auto;
}

/******************************
 Footer
*******************************/

footer {
  clear: both;
  margin-top: auto;
  z-index: 1;
}

.desktop.footer-reveal{
  .site-wrapper{
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      transform: translate3d(0,0,0);
      pointer-events: none;
    }
    > *:not(footer){
      z-index: 2;
    }
    header{
      z-index: 3;
    }
  }
  .top-row{
    position: relative;
    transform: translate3d(0,0,0);
  }
  footer{
    position: fixed;
    z-index: 0;
    width: 100vw;
    bottom: 0;
  }
}

/******************************
 Breadcrumbs
*******************************/

.breadcrumb {
  margin: 0;
  border-radius: 0;
  position: relative;
  padding: 0;
  background-color: transparent;
  width: 100%;
  margin: 0 auto;
  > li {
    position: relative;
    word-break: break-all;
    display: inline-flex;
    align-items: center;
    &:last-of-type {
      a {
        pointer-events: none;
        text-decoration: none;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 50%;
    margin-left: -50vw;
    top: 0;
    z-index: -1;
  }
  .fa {
    font-family: inherit;
  }
}

.boxed-layout {
  .breadcrumb::before {
    display: none;
  }
}

/******************************
 Empty page
*******************************/

#content h1 + p:only-of-type:last-child {
  margin: 0;
  padding: 10px;
}

/******************************
 Header Notice
*******************************/

.notice-module {
  overflow: hidden;
  order: -100;
  .module-body, .hn-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/******************************
 Block Expand
*******************************/

.expand-block {
  position: relative;
}

.block-expand {
  padding: 2px 6px;
  font-size: 10px;
  pointer-events: auto;
  &::after {
    font-weight: inherit;
    -webkit-font-smoothing: initial;
  }
}

.block-expand-overlay {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.expand-content.block-expanded {
  max-height: 100% !important;
  overflow: visible !important;
  padding-bottom: 25px;
  transition: all 0s !important;
  + .block-expand-overlay {
    background: none !important;
  }
}

.no-expand {
  .expand-content {
    max-height: 100% !important;
    overflow: visible !important;
  }
  .block-expand-overlay {
    display: none !important;
  }
}

/******************************
 Info Blocks
*******************************/

.module-info_blocks {
  .module-body {
    display: flex;
    flex-wrap: wrap;
  }
  .info-blocks-image {
    .info-block {
      &::before {
        display: none;
      }
    }
  }
  .info-block-img {
    position: relative;
  }
}

.info-block {
  position: relative;
  transition: all 0.075s ease-out;
  height: 100%;
  &::before {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    line-height: 1;
    text-align: center;
  }
  .count-badge {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.info-block-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/******************************
 Old Browser
*******************************/

.has-old-browser {
  position: fixed;
  overflow: hidden;
}

.old-browser {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  display: table;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
}

.ob-content {
  display: table-cell;
  vertical-align: middle;
}

.ob-text {
  padding: 10px 15px;
  font-size: 20px;
  display: block;
  max-width: 750px;
  margin: 0 auto;
}

.ob-links {
  a {
    display: inline-block;
    margin: 15px;
    img {
      display: block;
      margin-bottom: 5px;
      transform: scale(.9);
      transition: all 0.075s ease-out;
    }
    &:hover img {
      transform: scale(1);
    }
  }
}

/******************************
 Scroll to Top
*******************************/

.scroll-top {
  display: none;
  position: fixed;
  z-index: 10001;
  right: 20px;
  bottom: 10px;
  cursor: pointer;
  i {
    font-size: 50px;
  }
  &.scroll-top-active {
    display: block !important;
  }
}

/******************************
 Loader
*******************************/

.journal-loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 9990;
  background: rgba(0, 0, 0, .15);
}

.journal-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  i {
    &::before {
      content: "\e92f";
      font-family: $icons;
      line-height: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -51%);
    }
  }
  + iframe {
    z-index: 10;
  }
}

/******************************
 Site Loader
*******************************/

.j-loader {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 1000000;
  transition: transform 1s ease-in-out, opacity 6s ease;
  background: white;
}

.j-loader-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .journal-loading {
    position: static;
    transform: none;
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
}

.j-loaded {
  transform: translate3d(-50%, -50%, 0) scale(0);
  opacity: 0;
}

/******************************
 Catalog
*******************************/

.no-search {
  .mobile-search-wrapper {
    display: none !important;
  }
  .mobile-2 .mobile-search-group {
    display: none !important;
  }
}

.safari {
  .module-banners a,
  .module-gallery a,
  .category-thumb a,
  .manufacturer-thumb a {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
}

/******************************
 Span after breadcrumbs
*******************************/

.breadcrumb + span {
  height: 0
}

/******************************
 Default modal color
*******************************/

.modal {
  color: #333;
}

html.ie{
  overflow-x:hidden;
}
