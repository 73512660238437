@import '../variables.scss';


.block-header{
  display: flex;
  position: relative;
  z-index: 1;
  img{
    transition: all 0.1s ease-in-out;
    align-self: auto;
  }
  .icon{
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    transition: all 0.1s ease-in-out;
    &::before{
      transition: all 0.1s ease-in-out;
    }
  }
}

.module-testimonials{
  .block-header{
    display: block;
    .icon{
      font-size: 3em;
    }
  }
  .swiper-slide{
    cursor: -webkit-grab;
    cursor: grab;
    &:active{
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
  }
  .block-content{
    margin-bottom: 10px;
  }
  .block-footer{
    margin-top: auto;
  }
}

.block-wrapper{
  flex: 1;
  display:flex;
  flex-direction:column;
  width: 100%;
  border-radius: inherit;
}

.ie11 .block-wrapper{
  flex: auto;
}

.block-body{
  height: 100%;
  transition: all 0.1s ease-in-out;
  display: flex;
  flex-direction:column;
  flex-wrap: wrap;
  &::after{
    content: "";
    clear: both;
    display: table;
  }
  .title{
    width: 100%;
  }
}

.blocks-grid{
  .module-body{
    display: flex;
    flex-wrap: wrap;
  }
}

.block-content{
  position: relative;
  border-radius: inherit;
  &.block-image > img{
    border-radius: inherit;
  }
  > p:only-of-type{
    margin: 0;
  }
}

.block-footer{
  margin-top: auto;
  transition: all 0.1s ease-in-out;
  .btn{
    transition: all 0.1s ease-in-out;
  }
}

.block-map iframe {
  width: 100%;
  height: 100%;
}

.block-twitter {
  overflow-y: auto;
}

.module-blocks{
  height: inherit;
  .module-body{
    height: 100%;
  }
  .nav-tabs > li > a::before{
    margin-right: 5px;
  }
  .module-item{
    height: inherit;
  }
}

html[dir='rtl'] .module-blocks .nav-tabs > li > a::before{
  margin-right: 0;
  margin-left: 5px;
}

.mobile .product-blocks .expand-block {
  margin-bottom: 25px;
}
