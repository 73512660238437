@import '../variables.scss';

//Slider
.module-master_slider {
  &::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    transition: all 0.075s ease-out;
  }
  .slider-static-text{
    position: absolute;
    z-index: 2;
    display: block;
    //width: 100%; // interferes with position
    span{
      display: block;
      position: relative;
      transition: all 0.1s ease-out;
    }
  }
  div.slider-static-text{
    cursor: default;
  }
  max-width: 100%;
  background-size: cover;
  background-position: center;
  height: 100%;
  > img {
    visibility: hidden;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
  &.fullscreen-slider {
    > img {
      visibility: hidden;
      width: 100%;
      height: 100vh;
    }
  }

  iframe {
    border: none;
  }
  video {
    width: 100%;
    height: 100%;
  }
  .ms-slide{
    overflow: hidden;
    .ms-slide-video {
      z-index: 112;
    }
    &::before{
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 106;
      top: 0;
      pointer-events: none;
      //transition: all 0.15s ease-out;
      //transform: translateZ(0.3px);
    }
  }
  .ms-view {
    background: transparent;
  }
  .ms-layer-video {
    overflow: hidden;
  }
  .no-show-effect {
    transition-duration: 0s !important;
  }
  .ms-timerbar {
    height: auto !important;
    //z-index: 2; interferes with next row diagonal bg
  }
  .ms-time-bar {
    border-width: 0;
  }

  .ms-scroll-parallax-cont,
  .ms-slide .ms-slide-layers,
  .ms-overlay-layers .ms-slide-layers{
    z-index: 111;
    pointer-events: none;
    mix-blend-mode: normal;
  }

  .ms-slide .ms-layer,
  .ms-fixed-layers .ms-layer,
  .ms-overlay-layers .ms-layer {
    pointer-events: none;
  }

  .ms-slide-layers .ms-layer-button,
  .ms-slide-layers .ms-layer-hotspot{
    pointer-events: auto;
    transition: background-color 0.1s ease-out, color 0.1s ease-out, border-color 0.1s ease-out, box-shadow 0.1s ease-out, border-radius 0.1s ease-out;
  }

  .ms-slide-layers .ms-layer-video{
    pointer-events: auto;
  }

  //Hotspot
  .ms-point-center {
    background-clip: padding-box !important;
    cursor: context-menu;
    margin: -16px 0 0 -16px;
    background: white;
    line-height: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    &::before{
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      line-height: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50% );
    }
  }
  .ms-tooltip {
    max-width: 280px;
    position: absolute;
    z-index: 20;
    cursor: auto;
    .ms-tooltip-arrow {
      position: absolute;
      border-width: 8px;
      border-color: transparent;
      border-style: solid;
    }
  }
  .ms-tooltip-top {
    .ms-tooltip-arrow {
      top: auto;
      right: auto;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .ms-tooltip-right {
    .ms-tooltip-arrow {
      top: 50%;
      right: auto;
      bottom: auto;
      left: -16px;
      transform: translateY(-50%);
    }
  }
  .ms-tooltip-bottom {
    .ms-tooltip-arrow {
      top: -16px;
      right: auto;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .ms-tooltip-left {
    .ms-tooltip-arrow {
      top: 50%;
      right: -16px;
      bottom: auto;
      left: auto;
      transform: translateY(-50%);
    }
  }

  //Arrows
  .ms-nav-next,
  .ms-nav-prev {
    size: 50px;
    background: white;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    position: absolute;
    transition: all 0.1s ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #333;
    }
  }

  .ms-nav-next {
    left: auto;
    right: 0;
  }

  .ms-nav-prev::before {
    transform: scale(-1);
  }
  //Bullets
  .ms-bullets {
    transition: all 0.1s ease-out;
    right: auto;
    width: auto !important;
    .ms-bullets-count {
      right: auto;
      display: flex;
    }
  }
  .ms-bullet {
    transition: all 0.1s ease-in-out;
    border-width: 0;
    border-radius: 0;
    margin: 0 !important;
    background: white;
  }
  .ms-bullet-selected {
    background: #333;
  }
  .ms-thumb-list {
    bottom: 0;
    height: auto !important;
  }
  .ms-thumb-frame {
    transition: all 0.1s ease-out;
    opacity: 1;
    margin-right: 0 !important;
    position: relative;
    z-index: 1;
    &:hover {
      opacity: 1 !important;
    }
  }
  .ms-thumb-frame-selected {
    opacity: 1 !important;
    transform: scale(1);
    z-index: 2;
  }
  > .master-slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    > .ms-container {
      order: 1;
      margin: 0;
    }
  }
  .ms-btn-container{
    width: 100% !important;
    display: flex;
    align-items: center;
  }
}




html[dir='rtl']{
  .module-master_slider{
    .ms-btn-container{
      justify-content: flex-end;
    }
  }
}
