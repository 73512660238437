@import 'variables.scss';

/******************************
 General
*******************************/

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate3d(0, -5px, 0) scale(.95);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes scale-in {
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(.85);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.notification-wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999999999999999;
  pointer-events: none;
  display:flex;
  flex-direction:column;
}

.notification-wrapper-tc {
  align-items: center;
}

.notification-wrapper-tr {
  align-items: flex-end;
}

.notification-wrapper-center {
  align-items: center;
  justify-content: center;
  .notification{
    transform-origin: 50%;
    animation: scale-in .15s ease-out;
    &.fade-out {
      opacity: 0;
      margin: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translate3d(0, 0, 0) scale(.9);
    }
  }
}

.notification-wrapper-bottom {
  justify-content: flex-end;
  align-items: center;
  .notification{
    animation: slide-in .15s ease-out;
    transition: all 0.2s ease;
    &.fade-out {
      opacity: 0;
      margin: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translate3d(0, 30px, 0);
    }
  }
}

.notification {
  position: relative;
  background-color: white;
  pointer-events: auto;
  transition: all 0.3s ease-out;
  animation: fade-in .2s ease-out;
  transform-origin: 50% 0;
  border-radius: inherit;
  &.fade-out {
    opacity: 0;
    margin: 0;
    visibility: hidden;
    pointer-events: none;
  }
  &.removed {
    transition: margin-top 0s;
  }
}

.notification-content {
  display: flex;
  img{
    flex: 1 0 auto;
  }
}

.notification-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
  &:empty{
    margin:0;
  }
}

.notification-buttons {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  a::before {
    margin-right: 5px;
  }
  a::after {
    margin-left: 5px;
  }
}

.notification-close {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.075s ease-out;
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.module-notification{
  width: 100%;
  .notification-content{
    display: block;
  }
  .notification-close{
    align-items: center;
    justify-content: center;
    &::before{
      position: relative;
      top: initial;
      left: initial;
      transform: none
    }
  }
}


