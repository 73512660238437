@import '../variables.scss';

/******************************
 Links Menu
*******************************/

.links-menu {
  .module-body {
    flex-wrap: wrap;
    width: 100%;
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu-item {
    position: relative;
    border-width: 1px 0 0 0;
    transition: all 0.08s ease-out;
    display: flex;
    align-items: center;
    //width: 100%;

    &:first-child {
      border-top-width: 0;
      border-left-width: 0;
    }

    &.dropdown > a::after {
      display: none;
    }

    a {
      display: inline-flex;
      align-items: center;
      transition: all 0.08s ease-out;
      position: relative;
      width: inherit;
      //flex: 1;

      .menu-label {
        right: 0;
        top: auto;
        transform: translateX(100%);
      }

      &::before {
        line-height: 1;
      }

      &::after {
        display: none !important;
      }

      &:not([href]) {
        cursor: default;
      }
    }
  }
}

.phone footer .links-menu .module-title {
  &::before {
    position: absolute;
    right: 0;
    content: "\eb85";
    font-size: 18px;
    font-family: icomoon, sans-serif;
    top: 50%;
    transform: translateY(-50%);
  }
  &.closed{
    &::before{
      content: "\eba0";
    }
  }
}


