@import '../variables.scss';

.swiper {
  position: relative;
  width: 100%;
  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    > span{
      cursor: pointer;
      opacity: 1;
      transition: all 0.1s ease-out;
      border-radius:0;
      +span {
        margin-left: 8px;
      }
    }
    &:empty {
      padding:0;
    }
    .swiper-has-pages &{
      display: block;
    }
  }
}


.swiper-slide{
  height: auto;
  padding:0;
}

.swiper-buttons{
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
  > div{
    width: 35px;
    height: 35px;
    background: white;
    &::before{
      content: '\e408';
      font-family: icomoon;
    }
    &.swiper-button-next{
      &::before{
        content: '\e409';
      }
    }
  }
}


.swiper-button-next,
.swiper-button-prev{
  pointer-events: auto;
  margin-top:auto;
  transform: translateY(-50%);
  background: transparent;
  display: flex;
  align-items: center;
  left: 0;
  justify-content: center;
  &.swiper-button-disabled{
    pointer-events: auto;
  }
}
.swiper-button-next{
  left: auto;
  right: 0;
}

.swiper:not(.swiper-has-pages){
  .swiper-pagination,
  .swiper-button-next,
  .swiper-button-prev{
    display: none;
  }
}

.swiper-pagination-bullet:only-child{
  display: none !important;
}
