@import '../variables.scss';

.side-column{
  .module-filter{
    .panel-group{
      flex-direction:column;
    }
  }
}

html:not(.phone){
  .module-title{
    button{
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.reset-filter.btn{
  margin-left: auto;
}

html[dir='rtl']{
  .reset-filter.btn{
    margin-left: initial;
    margin-right: auto;
  }
}

.module-filter {
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;

  .module-title{
    width: 100%;
    button{
      margin-left: auto;
    }
  }

  .panel-group{
    display:flex;
    flex-direction:column;
    flex-wrap: wrap;
  }

  .panel-body{
    > div{
      > label{
        display: flex;
        align-items: center;
        margin:0;
        padding:5px;
        transition: all 0.05s ease;
        cursor: pointer;
        + label{
          border-width: 1px 0 0 0;
        }
        input{
          margin:0 2px 0 0;
          + img{
            transition: all 0.05s ease;
          }
        }
        .links-text{
          margin: 0 5px;
          transition: all 0.05s ease;
        }
        .count-badge{
          margin: 0;
          z-index: 2;
          transform: translateX(0);
        }
      }
    }
  }

  .image-only{
    .panel-body{
      > div{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        > label{
          display: flex;
          align-items: center;
          margin:0;
          padding:0;
          position: relative;
          + label{
            border-top: 0;
          }
          img{
            margin:0;
            //width: 100%;
            height: auto;
          }
          input{
            display: none;
          }
          .links-text{
            display: none;
          }
          .count-badge{
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }

  .links-text{
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }


  //Price
  .filter-price{
    padding-bottom:10px;
  }

  .range-slider {
    min-height: 40px;
    top: -18px;
    position: relative;
  }

  .irs{
    height: auto;
  }
  .js-range-slider {
    display: none !important;
  }
  .irs-min, .irs-max, .irs-from, .irs-to, .irs-single{
    display: none;
  }
  .irs-slider{
    background: $blue;
    border: none;
    box-shadow: none;
    transition: all 0.03s ease;
    border-radius:0;
  }
  .irs-line{
    background: transparent;
    border-radius: 0;
    border: none;
  }
  .irs-bar{
    background: transparent;
    border: none;
  }

  .extra-controls{
    display: flex;
    align-items: center;
    position: relative;
    input{
      width: calc(50% - 25px);
      text-align: center;
      border:none;
      box-shadow: none;
      outline: none;
    }
    .currency-symbol{
      padding:0 4px;
    }
    .currency-left{
      ~ .currency-left{
        margin-left: auto;
      }
    }
    input:first-child + .currency-right{
      margin-right: auto;
    }
  }

  .module-item {
    order: 999;

    .checkbox, .radio {
      margin-top: 0;
    }
    .select select {
      width: 100%;
    }
  }

  .panel-body{
    &::before, &::after{
      display: none;
    }
  }
}

html[dir='rtl']{
  .module-filter{
    .extra-controls{
      .currency-symbol{
        ~ .currency-symbol{
          margin-left: initial;
          margin-right: auto;
        }
      }
    }
  }
}

.mobile-filter-trigger {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  .has-bottom-menu &{
    margin-bottom: 45px;
  }
}

