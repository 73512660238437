@import '../variables.scss';

/******************************
 Gallery
*******************************/

.lg-backdrop{
  z-index: 100000;
  background-color: rgba(0,0,0,.85);
}
.lg-outer{
  z-index: 200000;
}

.lg-outer .lg-item{
  background: none;
  &::after{
    content: "";
    position: absolute;
    top: 46.5%;
    left: 50%;
    transform: translate(-50%, -50% );
    z-index: 1000;
  }
  &.lg-complete::after{
    display: none;
  }
}

.module-gallery {
  .module-body {
    display: flex;
    flex-wrap: wrap;
  }
  .module-item{
    > a{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      img{
        transition: all 0.15s ease-out;
        width: 100%;
        height: auto;
      }
      &::before{
        z-index: 1;
      }
      &::after{
        z-index: 2;
      }
      span{
        width: 100%;
        height: 100%;
        display: block;
        &::before{
          z-index: 1;
        }
      }
    }
  }
}

.lg-outer{
  .lg-toolbar{
    background: transparent;
    color: #ccc;
    .lg-icon{
      color: inherit;
      &:hover{
        color: white;
      }
    }
  }
  .lg-sub-html{
    background: transparent;
    pointer-events: none;
    transition: bottom 0.25s ease-out !important;
  }
  .lg-actions{
    .lg-icon{
      color: white;
      margin: 0;
      transform: translateY(-50%);
      border-radius:0;
      &::after{
        display: none;
      }
    }
  }
  #lg-zoom-out{
    display: none;
  }
  #lg-actual-size{
    font-size: 24px;
    &::after{
      content: "\e312";
    }
  }
  .lg-thumb-outer{
    height: auto !important;
    transition: transform .25s ease-out;
  }
  .lg-thumb{
    padding:0;
    margin:0 auto;
    background: transparent;
   // display: flex;
    align-items: center;
  }
  .lg-thumb-item{
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    overflow: visible;
    img{
      display: block;
    }
  }
  #lg-counter {
    color: inherit;
  }
}


.module-gallery {
  .module-item{
    > a{
      &::before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        transition: all 0.1s ease-out;
        opacity: 0;
      }
      &::after{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) scale(.5);
        transition: all 0.1s ease-out;
        opacity: 0;
        font-size: 35px;
      }
      &:hover{
        &::before{
          opacity: 1;
        }
        &::after{
          transform: translate3d(-50%, -50%, 0) scale(1);
          opacity: 1;
        }
        > span::before{
          transform: translate3d(0, 0, 0) scale(1);
          opacity: 1;
        }
      }
      > span{
        &::before{
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding:10px;
          transform: translate3d(0, 10px, 0) scale(1);
          transition: all 0.1s ease-out;
          opacity: 0;
          font-size: 12px;
          color: white;
          line-height: 1.3;
        }
      }
    }
  }
}

