@import '../variables.scss';

.module-catalog{
  .module-body {
    display: flex;
    flex-wrap: wrap;
  }
}

.subitem {
  line-height: 1.2;
  display: flex;
  max-width: 100%;
  a{
    transition: all 0.075s ease-out;
  }
  &::before {
    float: left;
  }
}

.catalog-title{
  display: flex;
}

.catalog-image{
  //margin-top: auto;
  img{
    width: 100%;
  }
}

.item-content{
  height: 100%;
  transition: all 0.1s ease-out;
  display:flex;
  flex-direction:column;
}

.item-assets {
  display: flex;
  flex: 1;
}

.subitems{
  flex-grow: 1;
  order:1;
  display:flex;
  flex-direction:column;
  .view-more a{
    display: flex;
    align-items: center;
  }
}

