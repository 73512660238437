@import '../variables.scss';

/******************************
 Icons Menu
*******************************/

.icons-menu {
  > ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    > .menu-item {
      margin: 0;
      position: relative;
      &:hover{
        z-index: 1;
      }
      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 22px;
        position: relative;
        .links-text {
          font-size: .47em;
          padding:0 5px;
          margin: 3px 0 -3px 0;
          width: 100%;
          text-align: center;
          line-height: 1.2;
        }
        .count-badge {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
        }
        &::before {
          content: "\F05E";
          font-family: $icons;
          line-height: 1;
        }
        &::after {
          display: none;
        }
      }
    }
  }
  .icon-menu-image{
    a::before{
      display: none;
    }
  }
}
