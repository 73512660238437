@import 'variables.scss';

/******************************
 Journal Grid
*******************************/

.grid-rows {
  display: flex;
  flex-direction: column;
}

.grid-row {
  position: relative;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &::before {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
  }
  .wave{
    pointer-events: none;
    position: absolute;
    width: 100%;
  }
  .wave-top{
    top: 0;
    left: 0;
    transform: translateY(-63%);
  }
  .wave-bottom{
    z-index: -1;
    bottom: 0;
    left: 0;
    transform: translateY(63%) scaleY(-1);
  }
}
.boxed-layout{
  .grid-row{
    &::before{
      width:100%
    }
  }
}

.mega-menu {
  .grid-row {
    overflow: hidden;
  }
}

/******************************
 Overlay Element
*******************************/
.grid-cols {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  height: 100%;
}

.grid-col {
  width: 100%;
  position: relative;
}

.module {
  position: relative;
}

.side-column {
  > .grid-rows {
    > .grid-row {
      > .grid-cols > .grid-col {
        width: 100% !important;
      }
    }
  }
}

.grid-items {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.grid-item {
  width: 100%;
}

.video-row {
  position: absolute;
  z-index: -100;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.video-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
}

/******************************
 Column Dimensions
*******************************/

.desktop {
 #top, #content-top,
 #bottom{
    .grid-col {
      &::before {
        pointer-events: none;
        position: absolute;
        padding: 0 4px;
        font-size: 12px;
        top: 0;
        left: 0;
        background-color: palegreen;
        z-index: 10;
      }
      &:hover::before {
        content: attr(data-dimensions);
      }
    }
  }

  #top .grid-cols > div:only-of-type::before {
    display: none;
  }
}

/******************************
 Demo
*******************************/

//#content {
//  .grid-row-1 {
//    background-color: aliceblue;
//  }
//  .grid-row-2 {
//    background-color: antiquewhite;
//  }
//  .grid-col-1 {
//    background-color: antiquewhite;
//  }
//  .grid-col-2 {
//    background-color: azure;
//  }
//  .grid-col-3 {
//    background-color: lightgreen;
//    > div:first-of-type{
//      background-color: lavender;
//    }
//  }
//}
