@import 'variables.scss';

/********************e**********
 Post Grid/List
*******************************/

.post-grid:not(.swiper-wrapper) {
  display: flex;
  flex-wrap: wrap;
}

.post-layout {
  display: flex;
  position: relative;
}

.post-thumb {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  position: relative;
  transition: all 0.075s ease-out;
  //overflow: hidden; //interferes with image and details shadow
  overflow: hidden; // for border radius
  .image {
    position: relative;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        pointer-events: none;
        transition: all 0.2s ease-out;
      }
    }
    .p-date{
      z-index: 1;
    }
  }
  &:hover {
    z-index: 2;
  }
  .caption {
    display: flex;
    flex-direction: column;
    flex: 1000 0 auto;
    position: relative;
    > * {
      order: 50;
    }
  }
  .name {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.3;
    margin: 0;
    display: flex;
    align-items: center;
    max-width: 100%;
    a {
      display: block;
    }
  }
  .description {
    margin: 0 0 auto 0;
    line-height: 1.3;
  }

  .post-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 12px;
    span {
      display: inline-flex;
    }
  }
}

.post-stats {
  ::before{
    line-height: 0;
  }
}

.p-date {
  i {
    padding: 0 4px;
    margin: 0;
    font-style: normal;
  }
}

.p-date-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding:5px 10px;
  pointer-events: none;
  line-height: 1;
  font-size: 16px;
  i{
    font-size: 10px;
  }
  &::before{
    display: none;
  }
}

//Buttons
.post-layout {
  .button-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .btn-text {
    display: none;
  }
}

.post-grid .button-group {
  margin-top: auto !important;
}

.post-list {
  .post-thumb {
    flex-direction: row;
    flex-wrap: wrap;
    .image {
      flex: 1 1 auto;
    }
  }
}

/******************************
 Post Page
*******************************/

.post-image {
  position: relative;
  break-inside: avoid;
  img {
    height: auto;
  }
}

.post-stats {
  span {
    align-items: center;
    margin-right: 8px;
    flex-wrap: wrap;
    display: inline-flex;
  }
}

.post-details {
  column-fill: balance;
}

.post-content::after {
  content: "";
  display: table;
  width: 100%;
  clear: both;
}

/******************************
 Comments
*******************************/

.blog-post {
  .comment {
    p {
      margin-top: 5px;
    }
  }
  .success {
    background: lightgreen;
    padding: 5px 10px;
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.post-comment {
  position: relative;
  .user-name {
    padding-right: 50px;
  }
}

.user-data {
  margin-top: 3px;
  ::before {
    margin-right: 5px;
  }
  span {
    display: inline-flex;
    align-items: center;
    max-width: 100%;
  }
  div span {
    margin-right: 5px;
  }
  a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.user-avatar {
  float: left;
  position: relative;
  z-index: 2;
}

.reply-form {
  margin-top: 20px;
}

.reply-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

/******************************
 Feed
*******************************/

.blog-feed {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin: 0 5px;
  }
}

html[dir='rtl'] {
  .blog-feed {
    right: auto;
    left: 10px;
  }
}

/******************************
 Modules
*******************************/

.module-blog_categories {
  .module-body {
    display: flex;
    flex-direction: column;
  }
  a {
    display: flex;
    align-items: center;
    transition: all 0.07s ease-out;
    + a {
      border-width: 1px 0 0 0;
    }
  }
}

/******************************
 Search
*******************************/

.box-search {
  display: flex;
  position: relative;
  .btn{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .fa{
      line-height: 0;
    }
  }
}

html[dir='rtl']{
  .box-search {
    .btn{
      right: auto;
      left: 0;
    }
  }
}

/******************************
 Comments
*******************************/

.module-blog_comments {
  .module-body > div + div {
    border-width: 1px 0 0 0;
  }
  .module-item {
    display: flex;
    > div {
      flex: 1;
      //padding: 0 7px;
      line-height: 1.3;
    }
    .side-subtitle {
      margin-top: 3px;
    }
  }
}

/******************************
 Side Posts
*******************************/

.side-posts.swiper-wrapper {
  flex-wrap: nowrap;
}

.side-posts {
  display: flex;
  flex-wrap: wrap;
  .post-thumb {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    .image {
      position: relative;
      flex: 1 0 auto;
      margin: 0 auto;
      max-width: 100%;
      a {
        display: block;
      }
      img {
        width: 100%;
      }
    }
    .post-stats {
      justify-content: flex-start;
    }
    .name {
      line-height: 1.1;
      margin-bottom: 3px;
    }
    .caption {
      padding: 0 8px;
      min-width: 0;
      flex: 1000 2 100px;
      width: 100%;
    }
  }
}
