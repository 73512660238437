@import 'variables.scss';

/******************************
 Wishlist
*******************************/

.route-account-wishlist {
  .table {
    margin-bottom: 0;
  }
  .table-responsive {
    table tr td {
      &:nth-of-type(2) {
        min-width: 175px;
      }
      &:last-of-type{
        white-space: nowrap;
      }
    }
    .btn{
      padding:10px
    }
    .fa-times,
    .fa-shopping-cart{
      &::before{
        font-size: 16px;
      }
    }
  }
}

[class*="route-account-"]:not(.route-account-address),
[class*="route-account-"]:not(.route-account-address-delete){
  table .text-right{
    .btn{
      min-width: 35px;
      min-height: 35px;
      margin:2px;
      i{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &::before{
          font-size: 16px;
        }
      }
    }
  }
  h1 + p{
    margin-bottom: 15px;
  }
}

/******************************
 Cart Page
*******************************/

.panels-total{
  display: flex;
  justify-content: space-between;
}

.cart-total {
  display: flex;
  justify-content: flex-end;
  //align-self: flex-end;
  .table {
    margin: 0 !important;
    td + td {
      width: 15%;
      min-width: 130px;
    }
  }
}
@media only screen and (max-width: 760px){
  .route-checkout-cart {
    .panels-total {
      .form-group .input-group {
        flex-direction: column;
        .input-group-btn {
          width: 100%;
          .btn {
            width: 100%;
            margin-left: 0;
            margin-top: 5px;
            height: 40px;
            padding:10px;
          }
        }
      }
    }
  }
  html[dir='rtl'].route-checkout-cart {
    .panels-total {
      .form-group .input-group {
        .input-group-btn {
          .btn {
            margin-left: initial;
            margin-right: 0;
          }
        }
      }
    }
  }
}
.route-checkout-cart {
  #content {
    .table-responsive {
      table {
        tbody {
          .td-qty {
            .input-group {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .input-group-btn {
              width: auto;
            }
          }
        }
        tr td {
          min-width: 90px;
          &:nth-of-type(2) {
            min-width: 175px;
          }
        }
      }
    }
    .cart-panels{
      flex: 1;
      p {
        margin-bottom: 10px;
      }
    }
  }
  #content {
    .cart-page,
    form,
    .cart-bottom{
      flex-grow: 1;
    }
    .cart-bottom{
      width: 100%;
    }
    table td {
      vertical-align: middle;
    }
  }
  #error-not-found{
    #content {
      flex-direction: column;
    }
  }
}

/******************************
 Default Checkout
*******************************/

.route-checkout-checkout {
  .table-responsive {
    table tr td {
      min-width: 90px;
      &:nth-of-type(1) {
        min-width: 175px;
      }
    }
  }
  #payment {
    > div > div > div {
      //max-width: 235px;
    }
    .col-sm-3 {
      max-width: 110px;
      padding-right: 0;
      float: left;
    }
  }
}

.phone.route-checkout-checkout {
  #payment {
    > div > div > div {
      max-width: 100%;
    }
  }
}

//Paypal Iframe
.table-responsive > iframe {
  margin: 0 !important;
  padding-top: 20px;
  width: 100%;
}

/******************************
 Sitemap
*******************************/

.route-information-sitemap {
  #content > .row {
    display: flex;
    flex-wrap: wrap;
    &::before{
      display: none;
    }
    > div {
      flex-grow: 1;
      > ul {
        padding: 0;
        ul{
          padding-left: 20px;
        }
        li {
          list-style: none;
          margin-top: 5px;
          a {
            &::before {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

/******************************
 Contact
*******************************/

.route-information-contact {
  .our-location {
    a > i {
      margin-right: 5px;
    }
    background: transparent;
    border: none;
    box-shadow: none;
    margin: 0;
    .panel-body {
      padding: 0;
    }
  }
  .other-stores{
    margin-bottom: 20px;
  }
}

/******************************
 Manufacturer
*******************************/

.route-product-manufacturer {
  .brand {
    h2 {
      margin-top: 15px;
    }
    .row {
      display: flex;
      flex-wrap: wrap;
      margin: -5px;
      &::before,
      &::after {
        display: none;
      }
      > div {
        padding: 5px;
        display: flex;
        width: 20%;
      }
    }
  }
  #content > h1 + p {
    padding: 0;
    margin-bottom: 10px;
  }
  .manufacturer {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      padding: 0 15px 15px 0;
    }
  }
}

.image-card {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  transition: all 0.15s ease;
  img {
    margin: 0 auto 5px auto;
  }
  > span {
    width: 100%;
    text-align: center;
    margin-top: auto;
    max-width: 120px;
    line-height: 1.1;
  }
}

.route-account-return-info{
  #content > * + *{
    margin-top: 20px;
  }
}

/******************************
 Compare
*******************************/

.route-product-compare {
  .compare-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 7px 0;
    .btn {
      flex-grow: 1;
      margin: 4px;
      &::before{
        display: none;
      }
    }
  }
  table
  #content{
    table{
      td{
        width: 20%;
      }
      td:not(:first-of-type) {
        min-width: 200px;
      }
    }
    tbody:last-of-type tr:last-child td {
      border-top-width: 1px;
      padding: 0;
    }
    .table-bordered>tfoot>tr>td{
      border: 0;
    }
  }
}

.route-product-compare #content table{
  >tbody>tr>td:first-child{
    white-space: nowrap;
  }
  >tfoot>tr>td{
    border-left:0;
    border-right:0;
  }
}
/******************************
 Search
*******************************/

.route-product-search {
  #content {
    .search-form {
      .inputs {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      input {
        width: auto;
        flex-grow: 1;
        max-width: 100%;
        margin-bottom: 10px;
      }
      select {
        max-width: 40%;
        margin-bottom: 10px;
        margin-left: 20px;
      }
    }
    > h2:last-child {
      display: none !important;
    }
    > h2:last-of-type {
      margin-top: 15px
    }
    > .checkbox {
      margin-bottom: 5px;
    }
  }
}
@media only screen and (max-width: 760px){
  .route-product-search #content .search-form .inputs{
    select, input{
      margin-left: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}

.route-account-order-info{
  .text-left:first-child{
    min-width: 150px;
  }
  .text-right{
    b{
      white-space: nowrap;
    }
  }
}

.route-information-information{
  #content{
    display:flex;
    flex-direction:column;
  }
  .content{
    flex: 1;
  }
}
