@import '../variables.scss';

ul.nav-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  border-width: 0 0 0 1px;
  &::before,
  &::after {
    display: none;
  }
  > li {
    margin: 0;
    display: flex;
    > a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.25;
      margin: 0;
      border: 0;
      border-radius: inherit;
      transition: all 0.07s ease-out;
      background: transparent;
      &::before {
        margin-right: 5px;
        font-weight: normal;
        line-height: 1;
      }
    }
    &:hover,
    &.active,
    &:focus{
      z-index: 2;
      > a {
        border: 0;
        background: transparent;
        &:hover,
        &:focus {
          //color: inherit;
          background: inherit;
          border: 0;
        }
      }
    }
    &:first-child {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      border-left-width: 0;
      > a {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
      }
    }
    &:last-child {
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
      > a {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }
  }
}

html[dir='rtl']{
  ul.nav-tabs {
    > li {
      &:first-child {
        //border-top-left-radius: 0;
        //border-bottom-left-radius: 0;
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        border-right-width: 0;
        > a {
          //border-top-left-radius: 0;
          //border-bottom-left-radius: 0;
          border-top-right-radius: inherit;
          border-bottom-right-radius: inherit;
        }
      }
      &:last-child {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        //border-top-right-radius: 0;
        //border-bottom-right-radius: 0;
        > a {
          border-top-left-radius: inherit;
          border-bottom-left-radius: inherit;
          //border-top-right-radius: 0;
          //border-bottom-right-radius: 0;
        }
      }
    }
  }
}

.tab-content {
  position: relative;
  border-width: 1px;
}

.tab-pane {
  padding: 0;
  background: transparent;
  border-radius: inherit;
}

.nav-tabs > li {
  z-index: auto;
  > a {
    position: relative;
    z-index: 1;
  }
}

.tab-container{
  position: relative;
  &::before {
    content: "";
    position: absolute;
    display: none;
    z-index: 0;
    width: 200vw;
    transform: translateX(-50%);
    top: 0;
    height: 40px;
  }
}

html[dir="rtl"]{
  .tab-container{
    &::before {
      transform: translateX(50%);
    }
  }
}
