@import '../variables.scss';

/******************************
 General
*******************************/

//iOS flex image stretch fix

.mobile {
  img {
    align-self: center;
  }

  input:not([type=button]):not([type=submit]),
  textarea {
    font-size: 16px !important;
  }

  select {
    padding: 0 !important;
    font-size: 16px !important;
  }

  * {
    background-attachment: scroll !important;
  }

  select:focus, textarea:focus, input:focus {
    font-size: 16px;
  }
}


/******************************
 Mobile Top Bar
*******************************/

.mobile-header-active {
  .mobile-header {

    .mobile-top-bar {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 2;
    }

    .mobile-top-menu-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .dropdown-backdrop {
    display: none;
    pointer-events: none;
  }

  #cart {
    background-color: transparent;

    i {
      border-radius: 0;
    }
  }
}

.mobile-custom-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;

  .count-badge {
    position: absolute;
    right: 0;
    top: 10px;
  }
}

html[dir='rtl'] {
  .mobile-custom-menu {
    .count-badge {
      left: 0;
      right: auto;
    }
  }
}

.mobile-main-menu-wrapper .main-menu.accordion-menu .j-menu .j-menu .dropdown > a::after {
  display: none !important;
}

html[dir="rtl"] .mobile-header .language-currency.top-menu > div:last-child .dropdown.drop-menu.animating > .j-dropdown{
  right:auto;
}

.mobile-header-active {
  .menu-trigger,
  .search-trigger,
  #cart > a > i {
    position: relative;

    &::after {
      display: block;
      font-size: 10px;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }

  #cart > a > i {
    &::after {
      transform: translate(-60%, -125%);
    }
  }

  .mobile-custom-menu {
    position: relative;

    .links-text {
      display: block;
      font-size: 10px;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -100%);

      &::after {
      }
    }
  }
}

html[dir='rtl'].mobile-header-active {
  #cart > a > i {
    &::after {
      transform: translate(-70%, -125%);
    }
  }
}


/******************************
 Mid Bar
*******************************/

.mobile-header-active {

  .mobile-bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    z-index: 1;
  }

  .mobile-bar-group {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  #cart a {
    cursor: pointer;
  }

  #cart-total {
    display: none !important;
  }

  .mobile-cart-wrapper,
  .mobile-top-menu-wrapper {
    .dropdown-menu::before {
      display: none !important;
    }
  }

  /******************************
   Logo
  *******************************/

  .mobile-logo-wrapper {
    height: 100%;
    width: auto;
    flex-grow: 1;
  }

  /******************************
   Main Menu Trigger
  *******************************/

  .menu-trigger {
    font-size: 20px;
    cursor: pointer;

    button {
      background: transparent;
    }

    span {
      display: none;
    }
  }

  /******************************
   Mini Search
  *******************************/
  .mini-search {
    #search {
      position: static;
      width: 100%;
      height: 100%;

      > button {
        max-width: 100%;
        height: 100%;
        display: block;
      }

      > .dropdown-menu {
        height: 40px;

        input {
          border-top-left-radius: inherit !important;
          border-bottom-left-radius: inherit !important;
        }
      }
    }

    .header-search {
      width: 100vw;
    }
  }

  .tt-menu {
    padding-top: 0;

    &::before {
      display: none;
    }
  }

  .full-search {
    #search {
      width: 100%;
      height: 100%;
    }
  }

  //Mobile 1
  .mobile-1 {
    display: flex;
    flex-direction: column;

    .mobile-logo-wrapper {
      max-width: 50%;
      flex-grow: 0;
    }

    .mobile-bar-group {
      height: 100%;

      > div {
        height: 100%;
      }
    }

    #cart {
      width: 100%;
      height: 100%;

      > a {
        height: 100%;
        justify-content: center;

        > i {
          height: 100%;
        }
      }
    }
  }

  //.mobile-2-old {
  //  .mobile-bar {
  //    padding: 0;
  //    height: 100%;
  //  }
  //  .mobile-logo-wrapper {
  //    width: 40%;
  //  }
  //  .top-menu {
  //    padding: 0 10px;
  //    > ul {
  //      flex-wrap: nowrap;
  //    }
  //  }
  //  .mobile-top-group {
  //    height: 100%;
  //    display: flex;
  //    flex-direction: column;
  //    flex-grow: 1;
  //    padding: 0;
  //    .mobile-top-bar {
  //      width: 100%;
  //      justify-content: flex-end;
  //      height: 42%;
  //    }
  //  }
  //  .mobile-menu-group {
  //    > div {
  //      margin-right: 7px;
  //    }
  //  }
  //  .mobile-bar-group {
  //    padding: 0;
  //    flex-grow: 1;
  //    display: flex;
  //    align-items: center;
  //    justify-content: space-between;
  //  }
  //  .mobile-search-wrapper {
  //    //background: transparent;
  //    .header-search {
  //      input {
  //        min-width: 100%;
  //      }
  //    }
  //  }
  //}

  // Mobile 2
  .mobile-2 {
    display: flex;
    flex-direction: column;

    .mobile-logo-wrapper {
      flex-grow: 1;
      max-width: none;
    }

    .mobile-bar > div {
      height: 100%;
      min-width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mobile-search-wrapper {
      width: 100%;
    }

    #cart {
      width: 100%;
      height: 100%;

      > a {
        height: 100%;
        justify-content: center;

        > i {
          height: 100%;
        }
      }
    }
  }

  // Mobile 3
  .mobile-3 {
    #logo {
      margin: 0 auto;
    }

    .mobile-bar {
      justify-content: space-between;
    }

    .mobile-custom-menu {
      padding: 0 15px;
    }

    .mobile-search-wrapper {
      flex: 1;
    }

    .mobile-bar-group {
      justify-content: space-between;
    }
  }

  header {
    z-index: 15;
  }
}

/******************************
 Top Menu Edge Dropdown
*******************************/

.mobile-header {
  .mobile-top-menu-wrapper {
    .top-menu > .j-menu > li:first-child {
      > .j-dropdown {
        left: 0;
        right: auto;
        transform: translate3d(0, -5px, 0);
        transition: all 0.075s ease-out;
      }

      &.animating > .j-dropdown {
        left: 0;
        right: auto;
        transform: translate3d(0, 0, 0);

        &::before {
          display: block !important;
          content: "" !important;
          transform: translateX(0);
          left: 10px !important;
        }
      }
    }
  }

  .language-currency {
    &.top-menu > div:last-child .dropdown.drop-menu {
      > .j-dropdown {
        left: auto;
        right: 0;
        transform: translate3d(0, -5px, 0);
        transition: all 0.075s ease-out;

        &::before {
          transform: translateX(-100%);
          left: calc(100% - 10px) !important;
        }
      }

      &.animating > .j-dropdown {
        left: auto;
        right: 0;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .language,
  .currency {
    .dropdown-toggle .symbol {
      width: auto;
      height: auto;
    }
  }
}

/******************************
 Mobile Sticky
*******************************/

.mobile-header-active .mobile-bar-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
