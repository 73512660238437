@import 'variables.scss';

/******************************
 Product Grid
*******************************/

.product-grid:not(.swiper-wrapper),
.isotope-grid,
.module-products-list .product-list:not(.swiper-wrapper) {
  display: flex;
  flex-wrap: wrap;
}

.product-layout {
  display: flex;
  position: relative;
  &:hover{
    z-index: 2;
  }
}

.product-thumb {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  position: relative;
  transition: all 0.075s ease-out;
  overflow: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  .image {
    position: relative;
    overflow: hidden;
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 100%;
      pointer-events: none;
      z-index: 1;
      transition: all 0.01s ease-out;
    }
  }
  .product-img {
    display: block;
    > div{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition:all 0.25s ease-out;
    }
    img {
      pointer-events: none;
      transition: all 0.2s ease-out;
      width: 100%;
      height: auto;
      &.img-second {
        visibility: hidden;
        opacity: 0;
        position: absolute;
      }
    }
  }
  .product-labels{
    z-index: 3;
  }
  &:hover {
    z-index: 1;
    .has-second-image {
      img {
        visibility: hidden;
        &.img-second {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .caption {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    > *{
      //order: 50;
    }
  }
  .name {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.3;
    margin: 0;
    display: flex;
    align-items: center;
    max-width: 100%;
    //flex-grow: 1;
    a {
      display: block;
      text-align: center;
    }
  }
  .stepper{
    height: auto;
  }
  .description {
    margin: 0 0 auto 0;
  }
  .price{
    > div{
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
    }
    span{
      line-height: 1;
    }
    .price-tax{
      line-height: 1.5;
    }
  }
  .price-tax {
    color: #999;
    display: block;
  }
  .countdown {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index:2;
  }
  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.safari{
  .product-thumb{
    .product-img,
    .product-labels{
      -webkit-transform: translateZ(1px);
      transform: translateZ(1px);
    }
  }
}

//Quickview
.product-layout {
  .quickview-button {
    position: absolute;
    z-index: 4;
    overflow: hidden;
    span {
      display: none;
    }
  }
}

//Buttons
.product-layout {
  .button-group,
  .wish-group,
  .extra-group{
    display: flex;
  }
  .btn-text {
    display: none;
  }
  .buttons-wrapper {
    //width: 100%;
    margin-top: auto;
    .button-group{
      position: static;
      transition: transform 0.2s ease-out;
    }
    a::before{
      line-height: 1;
    }
  }
  .rating {
    width: 100%;
    align-items: center;
    pointer-events: none;
    transition: all 0.1s ease-in-out;
  }
  .rating-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
  }
  .wish-group,
  .cart-group,
  .extra-group {
    position: relative;
    align-items: center;
  }
  .wish-group{
    transition: all 0.1s ease-out;
  }
}

.product-grid{
  .extra-group{
    > div{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      transition: all 0.2s ease-out;
    }
  }
  .button-group{
    align-items: center;
    flex-wrap: wrap;
  }
  .wish-group{
    pointer-events: none;
    a{
      pointer-events: auto;
    }
  }
}

.phone .btn-quickview{
  display: none !important;
}

.tablet .btn-quickview{
  display: none !important;
}

/******************************
Product List
*******************************/

.product-list {
  .product-thumb {
    display: block;
    .image {
      float: left;
      overflow: hidden;
    }
    .caption {
      > * {
        justify-content: flex-start;
      }
      .price {
        margin-top: 0;
        text-align: left;
      }
    }
    .rating {
      margin: 0;
      order: -1;
    }
    .name {
      margin: 0;
      padding-left: 0;
      padding-top: 0;
      flex-grow: 0;
      a {
        text-align: left;
      }
    }
    .description {
      display: flex;
      padding-top: 0;
      padding-left: 0;
      text-align: left;
      margin-bottom: 0;
      margin-top: 0;
    }
    .button-group {
      justify-content: flex-start;
      flex: 1;
    }
    .cart-group {
      justify-content: flex-start;
    }
    .caption {
      .buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        width: auto;
      }
    }
    .extra-group{
      width: auto;
      pointer-events: none;
      .btn{
        pointer-events: auto;
      }
      > div{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
  .stats {
    order: -2;
    > span {
      padding-right: 1em;
    }
  }
}

/******************************
 Side Products
*******************************/

.side-products{
  &:not(.swiper-wrapper){
    display: flex;
    flex-wrap: wrap;
  }
}

.side-product{
  width: 100%;
  display: block;
  position: relative;
  flex-direction: column;
  > div{
    float: left;
  }
  .image{
    position: relative;
    max-width: 100%;
    //height: 100%;
    a{
      height: 100%;
      display: flex;
    }
    img{
      width: 100%;
    }
  }
  .name{
    line-height: 1.1;
    a{
      display: block;
    }
  }
  .caption{
    height: 100%;
  }
  .rating-stars{
    display: inline-flex;
  }
  .price{
    display: flex;
    > span{
      line-height: 1.35;
    }
  }
}

.side-products-blocks{
  display: flex;
  flex-wrap: wrap;
  > div{
    margin-bottom: 20px;
  }
}

.mobile{
  .product-thumb .product-img > div,
  .module-gallery .module-item > a img,
  .post-thumb .image img,
  .manufacturer-thumb .image img{
    transform: scale(1) !important;
  }
  input{
    box-shadow: none;
  }
}
