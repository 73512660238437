@import 'variables.scss';

/******************************
 Product Page
*******************************/

.product-info {
  display: flex;
  flex-wrap: wrap;
  > div{
    position: relative;
  }
  .product-details{
    display:flex;
    flex-direction:column;
    .page-title{
      order: -100;
      display: none;
    }
  }
}
.product-blocks{
  display:flex;
  flex-direction:column;
}

/******************************
 Product Image
*******************************/

.product-image {
  position: relative;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  > div{
    order:5;
  }
  .main-image{
    overflow: hidden;
    cursor: zoom-in;
  }
  .swiper{
    width: auto;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .swiper.main-image{
    padding:0;
    .swiper-pagination{
      bottom: 10px;
    }
  }
  .additional-image{
    cursor: pointer;
  }
  .additional-images{
    &.swiper{
      padding: 0;
    }
  }
  .direction-vertical .additional-images{
    overflow: hidden;
    .swiper-container{
      overflow: visible;
    }
    .swiper-pagination{
      display: none !important;
    }
  }
  .additional-images:not(.swiper) {
    display: flex;
    flex-wrap: wrap;
  }
  .product-labels {
    z-index: 1;
  }
  img {
    width: 100%;
    height: auto;
  }
  &.direction-vertical {
    flex-direction: row;

    .additional-images {
      height: 0;
      overflow: visible;

      .swiper-container {
        width: 100%;
        height: 100%;
        overflow:hidden;
      }
      .swiper-wrapper {
        flex-direction: column;
      }
      .swiper-buttons{
        width: 100%;
        height: 100%;
        opacity: 0;
        > div{
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          max-width: 100%;
          &::before{
            transform: rotate(90deg);
          }
        }
        .swiper-button-next{
          top: auto;
          bottom: 0;
        }
      }
    }
  }
  &.position-left,
  &.position-top{
    .additional-images{
      order: -1;
    }
  }
}

.product-image.direction-vertical.additional-images-loaded .additional-images .swiper-buttons{
  opacity: 1;
}

//Cloud Zoom

.zm-viewer,
.zm-handler {
  pointer-events: none;
  z-index: 10;
}

.zm-viewer{
  opacity:0;
  transition: opacity .25s .25s ease-in-out;
}

/******************************
 Product Stats
*******************************/

.product-info {
  .product-stats {
    position: relative;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    b{
      font-weight: normal;
      margin-right: 3px;
    }
    ul{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin:0;
      overflow-x: auto;
      flex-grow: 1;
    }
    li{
      margin-right: 10px;
      display: flex;
      align-items: center;
      &::before {
        font-size: 7px;
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }
  .product-manufacturer {
    img{
      max-width: 150px;
    }
    &.brand-image{
      display: flex;
      justify-content: flex-end;
      flex:1;
      a{
        align-self: flex-start;
      }
    }
    > a{
      display: inline-flex;
      flex-direction: column;
      img{
        margin-bottom: 3px;
        align-self: center;
      }
      > span {
        text-align: center;
        display: block;
        max-width: 130px;
      }
    }
  }
  .custom-stats{
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    b{
      margin-right: 5px;
    }
    > div{
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-of-type{
        margin-right: 15px;
      }
    }
  }
}

html[dir='rtl']{
  .product-info{
    .product-stats {
      b{
        margin-right: initial;
        margin-left: 3px;
      }
      li{
        margin-right: initial;
        margin-left: 20px;
        &::before {
          margin-right: initial;
          margin-left: 5px;
        }
      }
    }
    .custom-stats{
      b{
        margin-right: initial;
        margin-left: 5px;
      }
      > div{
        &:first-of-type{
          margin-right: initial;
          margin-left: 10px;
        }
      }
    }
  }
}

/******************************
 Rating
*******************************/

.product-info{
  .rating{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > span{
      display: inline-flex;
      &:last-of-type{
        margin-right: 10px;
      }
    }
    div{
      a{
        display: inline-flex;
        margin:0 2px;
      }
    }
    b{
      font-weight: normal;
    }
  }
  .rating-stars{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
}

/******************************
 Price
*******************************/

.product-info{
  .price-group{
    display: flex;
    flex-wrap: wrap;
    pointer-events: auto;
    white-space: nowrap;
  }
  .product-price-group{
    z-index: 2;
    pointer-events: none;
    display: flex;
    .product-stats{
      pointer-events: auto;
      flex-grow: 1;
    }
  }
}

/******************************
 Default Options
*******************************/

.product-info{
  .product-options {
    .radio+.radio,
    .checkbox + .checkbox{
      margin:0;
    }
    h3{
      margin:0;
    }
    .img-thumbnail{
      border-radius:0;
      border-width:0;
      outline: none;
      padding:0;
      background-color: transparent;
    }
    .help-block{
      margin-bottom:0;
      &:empty{
        margin: 0;
      }
    }
    .option-value{
      margin: 0 5px;
      line-height: 1.2;
    }
  }
}

/******************************
 Push Options
*******************************/

.product-info{
  .product-options{
    .push-option {
      input,
      input + img + .option-value{
        display: none;
        position: absolute;
        width: 100%;
        text-align: center;
        border: none;
        padding:0;
        top: 100%;
        transform: translateY(-20%);
        min-height: auto;
        min-width: auto;
        background: none;
        line-height: 1;
        box-shadow: none;
      }
      .checkbox, .radio{
        padding:0 !important;
        margin:0 !important;
      }
      .option-value{
        margin:0;
      }
      > div{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        label{
          padding:0;
          position: relative;
        }
        .option-value{
          padding: 0 7px;
        }
        .option-value, img{
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.075s ease-out;
          &:hover{
            cursor: pointer;
          }
        }
        > div{
          width: auto;
        }
      }
    }
    .option-price{
      margin-left: 5px;
    }
  }
}

/******************************
 Add to Cart
*******************************/

.product-info{
  .product-details {
    .button-group-page{
      bottom: 0;
      left: 0;
      right: 0;
    }
    .buttons-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 auto;
      .alert {
        margin-top: 10px;
        padding: 5px 10px;
        width: 100%;
      }
      .extra-group {
        font-size: 0;
        display: flex;
        align-items: center;
        //flex: 1;
      }
    }
    .wishlist-compare {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .stepper-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      //flex: 1;
    }
    .stepper {
      min-width: 60px;
      overflow: visible;
      .control-label{
        position: absolute;
      }
      .form-control {
        padding-right: 25px;
        font-size: 1em;
      }
      span {
        i {
          width: 25px;
        }
      }
    }
  }
}

.popup-quickview{
  .main-image{
    cursor: default;
    .swiper-pagination,
    .swiper-buttons{
      display: none !important;
    }
  }
  .extra-group{
    display: none !important;
  }
  .description{
    position: relative;
  }
}

/******************************
 Product Tabs
*******************************/

.product-left{
  .product-blocks{
    margin-top: 20px;
  }
}

#tab-specification{
  .table{
    margin-bottom:0 !important;
  }
}

.review-links a{
  cursor: pointer;
}

#review{
  > p{
    margin:0;
  }
  table{
    margin-bottom: 0 !important;
    &:not(:nth-last-child(2)){
      border-bottom-width: 0 !important;
    }
  }
  .rating{
    margin-top: 5px;
  }
}

#form-review{
  .rate{
    display: flex;
    align-items: center;
    min-height: 35px;
    span{
      position: relative;
      top: -1px;
    }
    > *{
      margin: 0;
      + *{
        margin: 0 0 0 10px;
      }
    }
  }
  .help-block{
    margin-bottom: 0;
  }
  legend{
    margin-top: 15px;
  }
  h4{
    margin-top: 10px;
  }
}

.tags-title{
  margin-right: 5px;
}

.expand-content{
  > p:last-of-type{
    margin-bottom: 0;
  }
}
