@import '../variables.scss';

/******************************
 Cart
*******************************/
#cart {
  transition: all .05s ease-out;
  > a {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
    transition: all .05s ease-out;
    max-height: 100%;
    color: initial;
    &:hover {
      color: inherit;
    }
    > i {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: all .05s ease-out;
      font-size: 1.5em;
      max-height: 100%;
      font-family: inherit;
      span{
        position: relative;
        font-size: 12px;
        padding:0 5px;
        transition: all .05s ease-out;
      }
      &::before {
        content: "\e904";
        font-family: 'icomoon';
        transition: all .1s ease;
      }
    }
    &::after {
      display: none !important;
    }
  }
}

.mobile{
  #cart .cart-label{
    display: none;
  }
}

#cart-items {
  z-index: 1;
  top: 0;
  right: 0;
  margin: 0;
  position: absolute;
}

#cart-total{
  white-space: nowrap;
}

/******************************
 Cart Content
*******************************/

div.cart-content {
  font-size: 13px;
  right: 0;
  left: auto;
  padding-top: 0;
  &::before {
    left: 100%;
    transform: translateX(-150%);
  }
  .cart-empty {
    margin-bottom: 0;
    padding: 20px;
  }
  > ul {
    display: flex;
    flex-direction: column;
    background-color: white;
    list-style: none;
    margin:0;
    padding:0;
    overflow: hidden;
    .cart-products{
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }a
    .cart-totals {
      position: relative;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
      background-color: $f4;
    }
  }
  .cart-buttons {
    padding: 13px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-width: 1px 0 0 0;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
    a {
      margin: 0 6px;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  table {
    margin-bottom: 0 !important;
    border-style: none !important;
  }
  .cart-products {
    overflow-x: hidden;
    margin-bottom: -1px;
    table{
      border-bottom-width: 0 !important;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }
    tbody {
      > tr {
        &:nth-of-type(odd) {
          background-color: transparent;
        }
        &:first-of-type {
          > td {
            border-top: 0 !important;
          }
        }
        &:last-of-type > td {
          border-bottom-width: 1px;
        }
        > td {
          padding: 8px;
          border-width: 1px 0 0 0;
          background-color: transparent;
        }
      }
      .td-image {
        min-width: 60px;
      }
      .td-name{
        vertical-align: middle;
        //min-width: 100px;
        small {
          &::before {
            content: "•";
            margin: 0 3px;
          }
        }
      }
      .td-qty {
        white-space: nowrap;
      }
      .td-remove {
        padding:7px 5px;
        min-width: 25px;
        button {
          color: #999;
          font-size: 15px;
          position: relative;
          background-color: transparent;
          padding: 2px 0 0 0;
          i {
            float: left;
          }
          &:hover {
            color: #EA2349;
          }
        }
      }
    }
  }
  .cart-totals {
    border-width: 1px 0 0 0;
    z-index: 2;
    tbody {
      td {
        border-width: 1px 0 0 0;
        padding:12px;
      }
    }
    .td-total-text {
      width: 25%;
      white-space: nowrap;
    }
  }
  .table-bordered{
    border-width: 0;
  }
}
