@import '../variables.scss';


.category-grid{
  display: flex;
  &:not(.swiper-wrapper){
    flex-wrap: wrap;
  }
}

.manufacturer-grid{
  display: flex;
  &:not(.swiper-wrapper){
    flex-wrap: wrap;
  }
}

.category-thumb,
.manufacturer-thumb{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  //overflow-y: hidden; // name over image
  transition: all 0.075s ease-out;
  position: relative;
  .caption{
    width: 100%;
    display:flex;
    flex-direction:column;
    z-index: 2;
  }
  .description{
    flex-grow: 1;
  }
  .button-group{
    margin-top: auto;
  }
  .image{
    width: 100%;
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    img{
      width: 100%;
      height: auto;
      transition: all 0.18s ease;
      border-radius: inherit;
    }
  }
  .name{
    max-width: 100%;
    transition: all 0.075s ease-out;
    a{
      width: 100%;
      display: block;
    }
  }
}

.manufacturer-grid{
  display: flex;
  &:not(.swiper-wrapper){
    flex-wrap: wrap;
  }
}
.manufacturer-thumb{
  .name{
    width: 100%;
  }
  .caption {
    margin-top: auto;
  }
}