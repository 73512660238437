@import '../variables.scss';

/******************************
 Main Menu
*******************************/

.desktop-main-menu-wrapper {
  position: static;
  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
    bottom: 0;
    z-index: -1;
    height: 100%;
  }

  .main-menu {
    height: 100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    //second row items normal width
    &::after {
      //content: '';
      flex-grow: 99;
    }
    .main-menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      > a {
        border-width: 0 0 0 1px;
        justify-content: center;
      }
      &:first-of-type {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        > a {
          border-left-width: 0;
          border-radius: inherit;
        }
      }
      &:last-of-type {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        > a {
          border-right-width: 0;
          border-radius: inherit;
        }
      }
      &.dropdown {
        > a {
          &::after {
            transform: rotate(90deg);
            margin: 0 5px;
          }
        }
      }
    }
    > .j-menu {
      border-radius: inherit;
      flex-wrap: nowrap;
      > li > a {
        .links-text {
          width: 100%;
        }
      }
    }
  }

  &.menu-stretch {
    .main-menu {
      width: 100%;
      flex-wrap: nowrap;
      > ul {
        width: 100%;
        > li {
          flex-grow: 1;
        }
      }
    }
  }

  &.menu-center {
    text-align: center;
    .main-menu {
      margin: 0 auto;
      display: inline-flex;
    }
  }

  &.has-menu-2 {
    display: flex;
    align-items: center;
    //justify-content: space-between;
  }
}

html[dir='rtl']{
  .desktop-main-menu-wrapper{
    .main-menu .main-menu-item{
      > a{
        border-width: 0 1px 0 0;
      }
      &:first-of-type > a{
        border-right-width: 0;
      }
    }
  }
}

html[dir='rtl'] .desktop-main-menu-wrapper .main-menu>.j-menu>.first-dropdown::before{
  display: none !important;
}

/******************************
 Flyout
*******************************/

.main-menu > .flyout {
  .flyout-menu-item:first-child,
  .flyout-menu-item:first-child > a {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  .flyout-menu-item:last-child,
  .flyout-menu-item:last-child > a {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }
  > .dropdown-menu::before {
    left: 10px;
  }
}

/******************************
 Mega Menu
*******************************/

$pad: 20px;

.desktop-main-menu-wrapper {
  &.menu-center #main-menu{
    margin: 0 auto;
  }
  .main-menu {
    .mega-menu {
      &.mega-custom {
        position: relative;
      }
      .mega-menu-content {
        max-height: calc(100vh - 100px);
        margin: 0 auto;
      }
    }
    .menu-fullwidth {
      .mega-menu-content {
        max-width: 100%;
      }
    }
    .menu-fullwidth,
    .mega-fullwidth {
      > .j-dropdown {
        padding-top: 0;
        &::before {
          display: none;
        }
      }
    }
  }

  //Tall menu animation delay

  //.main-menu > .mega-menu {
  //  > .dropdown-menu {
  //    height: 0;
  //    overflow: hidden;
  //  }
  //  &.animation-delay {
  //    > .dropdown-menu {
  //      height: auto;
  //      overflow: initial;
  //    }
  //  }
  //}
}

/******************************
 //Multi columns
*******************************/
//
//.multi-level > .dropdown-menu > ul {
//  max-height: 150px;
//  display: flex;
//  flex-direction: column;
//  flex-wrap: wrap;
//  > li:last-of-type:nth-of-type(odd) {
//    border-bottom-width: 1px;
//  }
//  > li:nth-child(2n+1) {
//    border-top-width: 0; // Removes border on last item in column
//  }
//}

/******************************
 Menu Open Site Overlay
*******************************/

@keyframes overlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.desktop-main-menu-wrapper {
  .main-menu > .j-menu > .first-dropdown::before {
    z-index: 0;
    position: absolute;
    width: 150vw;
    height: calc(100vh - 60px);
    top: 100%;
    left: 0;
    pointer-events: none;
    animation: overlay .3s ease;
    opacity: 0;
    visibility: hidden;
    transition: all .15s .15s ease-in-out;
    content: "";
    display: none;
  }
  &.boxed-layout {
    .main-menu > .j-menu > .first-dropdown::before {
      display: none !important;
    }
  }
  .main-menu > .j-menu > .first-dropdown.mega-custom::before{
    width: 200vw;
  }
}

.header-sm .desktop-main-menu-wrapper {
  .main-menu > .j-menu > .first-dropdown::before {
    width: 150vw;
  }
}

.desktop-header-active .menu-open .main-menu > .j-menu > .first-dropdown::before {
  opacity: 1;
  visibility: visible;
  display: block;
}

.desktop-header-active .menu-hover .main-menu > .j-menu > .first-dropdown::before {
  opacity: 0;
  visibility: hidden;
  display: none;
}

/******************************
 Mobile Menu Desktop
*******************************/

.no-touchevents {
  &.mobile-menu-open {
    overflow: hidden;
  }
  .mobile-wrapper-open {
    overflow-y: scroll;
  }
}

.desktop-header-active {
  &.mobile-overlay {
    body {
      overflow: scroll;
    }
  }
  .menu-trigger {
    display: none !important;
    ul, li {
      height: 100%;
    }
    a {
      cursor: pointer !important;
      justify-content: flex-start;
      border-width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 5px 15px;
    }
  }
  &.mobile-menu-active {
    .menu-main-menu-wrapper {
      overflow-y: auto;
    }
    .site-wrapper #main-menu {
      display: none;
    }
    .menu-trigger {
      display: inline-flex !important;
      height: 100%;
    }
    .is-sticky {
      .menu-trigger {
        margin-right: auto;
      }
    }
  }
}





